import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-person.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "What is stress?",
  description: `Stress is a major cause of workplace absence and is on the rise.<sup>1</sup>
  The potential impact of stress will vary considerably depending on the individual and the level of stress they’re under.`,
  showClickOnATroubleSpotMessage: false,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    hotspots: [
      { x: 120, y: 0 }, // Cognitive/Moode
      { x: 130, y: 200 }, // Heart and Circulatory
      { x: 100, y: 320 }, // Digestive
      { x: 145, y: 480 }, // Other physical
    ],
    labels: ["Short term", "Long term"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <p>
        Also, small amounts of short-term stress can actually be helpful. This
        is known as ‘optimal stress’, where a shot of cortisol and adrenaline
        induced by moderate stress can actually increase output and
        productivity.<sup>2</sup>
        <br />
        <br />
        However, when this stress becomes long-term, it can lead to serious
        medical problems.
        <br />
        <br />
        Click on a trouble spot for more information.
        <br />
        <br />
      </p>
      <div
        className={
          `graphic -valign` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Cognitive/Mood</h3>
              <ul>
                <li>Reduced reasoning/concentration</li>
                <li>Avoidant behaviour</li>
                <li>Loss of memory</li>
                <li>Reduced confidence</li>
                <li>Irritation and anger</li>
                <li>Possible mental health problems</li>
                <li>Panic attacks</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Heart and circulatory</h3>
              <ul>
                <li>Increased ‘fight or flight’ response</li>
                <li>
                  Increased heart rate/rising blood pressure — might lead to
                  cold/hot sweats.
                </li>
                <li>Breathlessness</li>
                <li>Tight chest</li>
                <li>Fainting</li>
                <li>
                  Increased risk of chronic high blood pressure, stroke or heart
                  attack
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Digestive</h3>
              <ul>
                <li>Butterflies, nausea, dry mouth</li>
                <li>Constipation</li>
                <li>
                  Increased glucose in blood leading to blood sugar imbalance or
                  diabetes
                </li>
                <li>Weight loss or weight gain</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Other physical</h3>
              <ul>
                <li>Muscle tension</li>
                <li>Reduced sex drive</li>
                <li>Headaches</li>
                <li>Restlessness/fidgeting</li>
                <li>Back, neck and shoulder problems</li>
                <li>
                  Exhaustion/loss of energy/finding it difficult to get up in
                  the mornings
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
