import React, { Component } from "react";
// import { Pie } from "../../../components/Pie";
// import icons from "../../../components/Icons";
// import Oblong from "../../../components/Oblong";
const info = {
  title: "What are MSDs?",
  description: `MSD stands for musculoskeletal disorder, which relates to injuries or disorders of the muscles, nerves, tendons, joints, cartilage, and spinal discs.`,
};

export default class extends Component {
  static info = info;

  render = () => {
    return (
      <>
        {/* Removed as no longer present in https://www.dropbox.com/sh/k807e6jmtllxwvr/AAD0VEw6SmHLv7I7qtLNGt8ba/4.%20Musculoskeletal%20disorders.docx?dl=0
        https://2-app.donedone.com/6110/project/20646/task/1108941
         */}
        {/* <h3>The musculoskeletal system allows the body to move</h3>
        <div className="icon-row">
          {icons.person}
          <div>
            <p>It includes not only the bones and muscles, but also:</p>
            <ul className="columns--2">
              <li>Cartilage</li>
              <li>Tendons</li>
              <li>Ligaments</li>
              <li>Joints</li>
              <li>Any other connective tissue</li>
            </ul>
          </div>
        </div> */}
        <h3>MSDs are common and can be either acute or chronic</h3>
        <div className="cards">
          <div className="card">
            <h4>Acute</h4>
            <ul>
              <li>Acute pain begins suddenly and is usually sharp</li>
              <li>Can last for several weeks or months</li>
              <li>Can lead to chronic pain</li>
            </ul>
          </div>
          <div className="card">
            <h4>Chronic</h4>
            <ul>
              <li>Typically, chronic pain lasts longer than 6 months</li>
              <li>It persists even though an initial injury may have healed</li>
              <li>The pain can sometimes last years</li>
            </ul>
          </div>
        </div>
        <h3>Why tackle MSDs at work?</h3>
        <p>
          470,000 employees are estimated to have an MSD<sup>1</sup>, with
          employees requiring an average of 18 days off work for each
          work-related musculoskeletal injury.<sup>2</sup>
        </p>
        <p>
          Sadly, depression and chronic MSDs are often deeply connected.
          <sup>3</sup>
        </p>
        <p>Below are reasons for employers to tackle MSDs in the workplace.</p>
        <h4>Ethical reasons</h4>
        <p>
          Ensuring your business maintains a good reputation and motivated
          workforce by:
        </p>
        <ul>
          <li>Being proactive</li>
          <li>Taking action to resolve any issues</li>
        </ul>
        <h4>Legal reasons</h4>
        <ul>
          <li>
            Employers have a legal duty to make reasonable adjustments under the
            Equality Act 2010
          </li>
          <li>
            Employers must provide training and equipment to ensure a safe
            environment
          </li>
        </ul>
        <h4>Financial reasons</h4>
        <ul>
          <li>Can reduce absence and save costs in sick pay</li>
          <li>
            Helps keep key skilled employees, reduce the cost of temps,
            recruitment and training new employees
          </li>
        </ul>
        {/* Removed as no longer present in https://www.dropbox.com/sh/k807e6jmtllxwvr/AAD0VEw6SmHLv7I7qtLNGt8ba/4.%20Musculoskeletal%20disorders.docx?dl=0
        https://2-app.donedone.com/6110/project/20646/task/1108941
         */}
        {/* <Oblong>
          <ul className="facts">
            <li className="fact">
              <Pie value={35} />
              <blockquote>
                37% of working days were lost to MSDs in 2019
              </blockquote>
            </li>
            <li className="fact">
              {icons.calendar}
              <blockquote>And last an average of 14 days per case</blockquote>
              <cite>
                <a
                  href="https://www.hse.gov.uk/statistics/causdis/msd.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Work related musculoskeletal disorder statistics (WRMSDs) in
                  Great Britain, 2019
                </a>
              </cite>
            </li>
            <li className="fact">
              {icons.depression}
              <blockquote>
                Employees off work for an MSD can also be at risk of developing
                symptoms of depression within 12 months of their injury
              </blockquote>
              <cite>
                <a
                  href="https://fitforwork.org/blog/musculoskeletal-disorders-msds-and-depression/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fit for Work: Musculoskeletal disorders and depression
                </a>
              </cite>
            </li>
          </ul>
        </Oblong> */}
      </>
    );
  };
}
