import { Component } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.router.location !== prevProps.router.location) {
      window.scrollTo({
        top: 0,
        left: 0,
        // behavior: "smooth"
      });
      document.getElementById("root").scrollTo({
        top: 0,
        left: 0,
        // behavior: "smooth"
      });
    }
    // Send a message to any page that contains this site as an iframe, so it can trigger a scroll to top there too (although we believe client no longer requires iframe usage)
    window.parentSource &&
      window.parentSource.postMessage({ tdIframeForceScrollUp: true }, "*");
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
