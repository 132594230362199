import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-grief-3.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Working through bereavement",
  description: `Working through bereavement can be an emotional rollercoaster as people alternate between feelings of loss and gradually coming to terms with the future.
  Grief, if ignored or unacknowledged, can be triggered months or years later by a related or even unrelated event.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Loss", "The future", "External factors"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Loss</h3>
              <p>Loss may include:</p>
              <ul>
                <li>Grief impacting on daily life</li>
                <li>Thinking about death</li>
                <li>Denial/avoiding necessary changes</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>The future</h3>
              <p>The future may include:</p>
              <ul>
                <li>Adapting to life changes</li>
                <li>Doing new things</li>
                <li>New roles/identities/relationships</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>External factors</h3>
              <p>
                External factors can impact the way that individuals deal with
                bereavement depending on factors including:
              </p>
              <ul>
                <li>Personal vulnerability and/or experience</li>
                <li>Type of death</li>
                <li>Social/family network</li>
                <li>
                  Who the person was – for example the death of a child is
                  always hard, regardless of age
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
