import React, { Component } from "react";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Resource from "../../../components/resource";
import { OtherModulesLink } from "../../../components/OtherModulesLink";
const info = {
  title: "Useful links and sources",
  description: ``,
};
export default class extends Component {
  static info = info;
  componentDidMount() {
    console.log(this);
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h2 className="shift-up">Overview</h2>
      <div className="resources">
        {[
          {
            title:
              "The Rich Picture - People of working age with cancer - Macmillan 2017",
            url: "https://www.macmillan.org.uk/_images/people-of-working-age-with-cancer_tcm9-282791.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>What is cancer?</h2>
      <div className="resources">
        {[
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/cancer-information-and-support/worried-about-cancer/what-is-cancer",
          },
          {
            title: "Cancer Research UK",
            url: "https://www.cancerresearchuk.org/about-cancer",
          },
          {
            title: "NHS",
            url: "https://www.nhs.uk/conditions/cancer/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Surgery</h2>
      <div className="resources">
        {[
          {
            title: "Cancer Research UK",
            url: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/surgery",
          },
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/information-and-support/treating/surgery/surgery-explained/what-is-surgery-and-what-is-it-used-for.html",
          },
          {
            title: "Maggie's",
            url: "https://www.maggiescentres.org/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Chemotherapy</h2>
      <div className="resources">
        {[
          {
            title: "Cancer Research UK",
            url: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/chemotherapy",
          },
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/information-and-support/treating/chemotherapy/chemotherapy-explained",
          },
          {
            title: "NHS",
            url: "https://www.nhs.uk/conditions/chemotherapy/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Radiotherapy</h2>
      <div className="resources">
        {[
          {
            title: "Cancer Research UK",
            url: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/radiotherapy",
          },
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/information-and-support/treating/radiotherapy/radiotherapy-explained",
          },
          {
            title: "NHS",
            url: "https://www.nhs.uk/conditions/radiotherapy",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Immunotherapy</h2>
      <div className="resources">
        {[
          {
            title: "Cancer Research UK",
            url: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/immunotherapy/",
          },
          {
            title: "Cancer Research UK - What is Immunotherapy?",
            url: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/immunotherapy/what-is-immunotherapy",
          },
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/cancer-information-and-support/treatments-and-drugs/immunotherapy",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Targeted Therapy</h2>
      <div className="resources">
        {[
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/information-and-support/treating/targeted-biological-therapies",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Psychological side effects</h2>
      <div className="resources">
        {[
          {
            title: "Cancer Research UK",
            url: "https://www.cancerresearchuk.org/about-cancer/coping/emotionally",
          },
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/information-and-support/coping/your-emotions",
          },
          {
            title: "Macmillan",
            url: "https://www.macmillan.org.uk/information-and-support/coping/side-effects-and-symptoms/other-side-effects/chemo-brain.html",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <OtherModulesLink />
    </>
  );
}
