import { info, CpdCertificate } from "../../cpd-certificate";
const _module = CpdCertificate({
  cpdCertificateUrl: "",
  content: "",
});
_module.info = {
  ...info,
  description: `You have now completed our <em>Bereavement</em> e-module. We hope you found it useful and now feel more informed about the big impact bereavement can have you you, your employees and your business.`,
};
export default _module;
