import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-leaf.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Biotherapy/immunotherapy",
  description: `Many types of biological therapy or immunotherapy are still experimental but can be the best treatment for some cancers depending on the stage of the cancer and other treatments that have been used.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Myths", "Facts", "Side Effects", "Duration"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <div className="myths">
              <h3>{info.title} myths</h3>
              <h5>Myth</h5>
              <p>
                Biotherapies have fewer side effects than other cancer
                treatments.
              </p>
              <h5>Truth</h5>
              <p>
                Side effects can actually include pain, swelling, soreness,
                redness, itchiness and rash at the site of infusion or
                injection. They can also cause an array of flu-like symptoms
                including muscle aches, fatigue, dizziness, nausea or vomiting.
                There can also be long-term side effects.
              </p>
              <h5>Myth</h5>
              <p>Biotherapies are still being researched so cannot be used.</p>
              <h5>Truth</h5>
              <p>
                Although many forms of biological therapy have been approved,
                others remain experimental and are available to cancer patients
                principally through participation in clinical trials (research
                studies involving people).
              </p>
            </div>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>{info.title} facts</h3>
              <p>
                These are often drugs which mimic substances that occur
                naturally in the body to destroy cancer cells and work by
                changing the way that cells interact or signal to each other.
              </p>
              <h4>They may:</h4>
              <ul>
                <li>Stop cancer cells from dividing and growing</li>
                <li>Seek out cancer cells and kill them</li>
                <li>Encourage the immune system to attack cancer cells</li>
              </ul>
              <p>They are not available for all types of cancer.</p>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>{info.title} side effects</h3>
              <h4>Possible side effects of biotherapies</h4>
              <ul>
                <li>Rashes</li>
                <li>Swelling</li>
                <li>
                  Flu-like symptoms, including fever, nausea, vomiting, loss of
                  appetite
                </li>
                <li>Fatigue</li>
                <li>Changes in blood pressure</li>
                <li>Bone pain</li>
                <li>Allergic reactions</li>
                <li>Muscle aches</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>{info.title} duration</h3>
              <ul>
                <li>Treatment can last for many months</li>
                <li>
                  Often administered on a 3-weekly basis (such as Herceptin)
                </li>
                <li>
                  Can be used following other treatments such as surgery and
                  chemotherapy
                </li>
                <li>
                  Side effects can last as long as, and slightly longer than,
                  treatment
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
