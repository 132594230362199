import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-desk.svg";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
const info = {
  title: "COVID-19 and stress",
  description: `Of course, we can’t forget stress relating to COVID-19. The pandemic brought a landslide of potential stressors, some new and some that already existed but have manifested in new ways due to the pandemic.
`,
  showClickOnATroubleSpotMessage: false,
};
export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    hotspots: [
      { x: 20, y: 33 }, // horizontal reading rows
      { x: 210, y: 37 },
      { x: 420, y: 10 },
      { x: 190, y: 152 },
      { x: 300, y: 190 },
      { x: 20, y: 265 },
      { x: 155, y: 357 },
      { x: 295, y: 330 },
    ],
    labels: ["Short term", "Long term"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      {/* <style>{`.hotspot { opacity: 0.2!important }`}</style> */}
      <p></p>
      <p>
        Staff may also feel stress about returning to the workplace. With this
        in mind, it’s vital to ensure your policies for tackling workplace
        stress have evolved with the times.
      </p>
      <p>
        Click on a trouble spot for more information about potential
        pandemic-related stressors.
      </p>
      <br />
      <br />
      <div
        className={
          `graphic -valign` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>
                Difficulties maintaining a work/life balance with hybrid
                working:
              </h3>
              <p>
                Working from home provided many of us with more freedom to
                balance our work and home lives. This might involve increasing
                exercise, spending more time with the family, lower childcare
                expenses and more time to keep up with household chores. With
                hybrid working meaning at least some days in the office, staff
                might struggle to achieve that same balance with less time at
                home.
              </p>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Changes to sleeping patterns:</h3>
              <p>
                When we’re fully in the office or fully working from home, we
                can fix our sleep schedules. This is good for quality of sleep
                and our overall health. However, hybr id working, with some days
                waking up early to commute and others working from home, can
                disrupt this. Encourage employees to adopt healthy sleeping
                habits -{" "}
                <a
                  href="https://www.unum.co.uk/article/hybrid-work-and-your-sleep-habits"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  see more here
                </a>
                .
              </p>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>
                Challenges adapting caring commitments at home after returning
                to a fixed place of work:
              </h3>
              <p>
                Home working made caring for young children or ageing relatives
                easier thanks to increased availability. It may even have saved
                on childcare costs as the kids could stay at home under an
                employee's watchful eye. Going back to work could throw this off
                balance and see a rise in childcare costs once again. Ensure you
                have policies in place to support those caring for others - Unum
                research finds that around
                <a
                  href="https://www.unum.co.uk/article/who-cares-for-the-carers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  6 million employed workers are caring for both an ageing
                  relative and young children
                </a>
                .
              </p>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Stress from returning to a commute:</h3>
              <p>
                Working from home erased commutes from employees' days, freeing
                up more time to spend at home and reducing stress from traffic
                or delayed public transport. A return to commuting might
                abruptly bring these stressors back to employees' lives.
                Consider flexible hours so employees can choose a start and
                finish time outside of rush hour.
              </p>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>
                Social anxiety about meeting people in-person again after
                virtual meetings became the norm:
              </h3>
              <p>
                For the past few years, many of us have worked from home and
                attended meetings virtually. It might take a while to adjust to
                in-person meetings again, with social anxiety rising as a
                result. Ensure staff have all the support they need to ease
                themselves back into the workplace, especially for those who
                suffer from conditions such as anxiety already.
              </p>
            </>
          )}
          {this.state.svgActive === "5" && (
            <>
              <h3>
                Concerns about managing altered relationships with colleagues or
                even workplace bullying/harassment as in-person working returns:
              </h3>
              <p>
                Being back in the workplace can cause workplace tensions to
                rise. Ensure you have proper policies in place to counteract
                bully, harassment or other negative inter-colleague behaviour.
              </p>
            </>
          )}
          {this.state.svgActive === "6" && (
            <>
              <h3>Worries about infection:</h3>
              <p>
                Being back in the workplace can cause workplace tensions to
                rise. Ensure you have proper policies in place to counteract
                bully, harassment or other negative inter-colleague behaviour
              </p>
            </>
          )}
          {this.state.svgActive === "7" && (
            <>
              <h3>Anxiety about going back to the workplace in general:</h3>
              <p>
                Going back to the workplace will be a big change for those
                whotve got used to working mostly at home. Anxiety might come
                from simply having to go back to the office, or related to
                perceived difficulties with juggling caring responsibilities.
                Ensuring you offer flexible working for employees could help in
                this area.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
