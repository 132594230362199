import React, { Component } from "react";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Resource from "../../../components/resource";
import { Link } from "react-router-dom";
import { OtherModulesLink } from "../../../components/OtherModulesLink";
const info = {
  title: "Useful links and sources",
  description: ``,
};
export default class extends Component {
  static info = info;
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h2 className="shift-up">Related links</h2>
      <div className="resources">
        {[
          {
            title: "Bereavement: Employer and employee guide",
            url: "https://www.unum.co.uk/docs/CR00550NLW.pdf",
          },
          {
            title: "Bereavement: Guide for managers",
            url: "https://www.unum.co.uk/docs/UP3327.pdf",
          },
          {
            title: "Effects of grief and bereavement",
            url: "http://online.positiveimagesuk.com/unum/download/UP3324.pdf",
          },
          {
            title: "St Catherine's Hospice: Support, help and advice",
            url: "https://www.stch.org.uk/",
          },
          {
            title: "Working through bereavement",
            url: "https://www.unum.co.uk/docs/UP3325.pdf#",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <OtherModulesLink />
    </>
  );
}
