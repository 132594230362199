import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-clock-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting an employee's return to work",
  description: `A person doesn’t have to be fully recovered to return to the workplace - and work can often be the factor that helps them recover.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    // Put these in if we need to re-use the graphic
    // labels: [
    //   "Training",
    //   "Return to work planning"
    //   "Return to work interviews",
    //   "Resources",
    // ]
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Training</h3>
              <ul>
                <li>
                  Line managers have a central role to play in an employee’s
                  return to work
                </li>
                <li>
                  Offer manager training on dealing with absence and supporting
                  employees at the time of induction or promotion
                </li>
                <li>
                  Encourage and build a culture of employee wellbeing and early
                  intervention
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Return to work planning</h3>
              <ul>
                <li>Carry on regular communication</li>
                <li>
                  Discuss their role and document any short-term adjustments
                </li>
                <li>Possible phased approach to return — building up hours</li>
                <li>Is a workplace risk assessment needed?</li>
                <li>Agree time off for appointments/treatment</li>
                <li>Book date to review progress</li>
                <li>Consider the impact on team</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Return-to-work interviews</h3>
              <ul>
                <li>Helps to understand the employee’s concerns</li>
                <li>
                  Are considered to be very effective in managing absence (CIPD
                  2020)
                </li>
                <li>
                  Should be held ASAP before employee returns to allow time to
                  discuss options
                </li>
                <li>Discuss and agree what to say to colleagues</li>
                <li>Consider using an interview template.</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Resources</h3>
              <h4>Consider what you need and what you can access:</h4>
              <ul>
                <li>Ergonomic assessments</li>
                <li>Employee Assistance programme (EAP)</li>
                <li>Occupational Health</li>
              </ul>
              <h4>Some resources may be built into your staff benefits:</h4>
              <ul>
                <li>Private Medical Insurance</li>
                <li>Vocational Rehabilitation Consultants</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
