import React from "react";
import calendar from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-calendar.svg";
import certificate from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-certificate.svg";
import depression from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-depression.svg";
import ethical from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-ethical.svg";
import legal from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-legal.svg";
import stress from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-stress.svg";
import one from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-one.svg";
import two from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-two.svg";
import three from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-three.svg";
import spiral from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-spiral.svg";
import personBody from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-person-body.svg";
import personArm from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-person-arm.svg";
import personShadow from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-person-shadow.svg";
import health from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-health.svg";
import healthHandle from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-health-handle.svg";
import healthBody from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-health-body.svg";
import healthSymbol from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-health-symbol.svg";
import stressLine from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-stress-line.svg";
import stressLine2 from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-stress-line-1.svg";
import stressLine3 from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-stress-line-2.svg";
import stressPerson from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-stress-person.svg";
import headCog from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-head-cog-1.svg";
import headCog2 from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-head-cog-2.svg";
import headCog3 from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-head-cog-3.svg";
import head from "-!svg-inline-loader?removeSVGTagAttrs=false!./svg/icon-head.svg";

const svgs = {
  calendar,
  certificate,
  depression,
  ethical,
  legal,
  stress,
  one,
  two,
  three,
  spiral,
  // Icons can also be a collection of SVGs, layered up
  person: [personBody, personArm, personShadow],
  health,
  healthLayered: [healthHandle, healthBody, healthSymbol],
  stressLayered: [stressLine, stressLine2, stressLine3, stressPerson],
  headLayered: [head, headCog, headCog2, headCog3]
};

// Loop over the SVGs, converting them into actual DOM jank
for (let i in svgs) {
  // If passed an array of SVGs, join 'em up
  let wasArray = false;
  if (Array.isArray(svgs[i])) {
    wasArray = true;
    svgs[i] = svgs[i].join("");
  }
  svgs[i] = (
    <div
      className={`icon--${i} ${wasArray ? "-layers" : ""}`}
      dangerouslySetInnerHTML={{
        __html: svgs[i]
      }}
    />
  );
}

export default svgs;
