import { winHeight } from "./utilities";

export const cssAnimationTriggersAfterSlideIn = () => {
  // Also, while we're here.. (re-)trigger some CSS animations on Pie charts and stuff if they're sliding in slowly.
  // The way it works for Pie paths is tricky to explain. The paths opacity fade in by .animatie-all-children-slider-after-header, but during which it has visibility hidden in _pie.scss. When it reaches opacity 1 (but still hidden) it then allows this interval to add the triggered class
  setInterval(() => {
    let cssAnimatedThingsToTrigger = [
      ...document.querySelectorAll(".pie path, .trigger-me")
    ];
    if (cssAnimatedThingsToTrigger.length) {
      cssAnimatedThingsToTrigger.forEach(c => {
        if (!c.classList.contains("triggered")) {
          const { top, height } = c.getBoundingClientRect(),
            onScreen = top < winHeight() && top + height > 0,
            opacity = parseFloat(
              window.getComputedStyle(c).getPropertyValue("opacity")
            );

          // If on the screen, and fully visible add triggered class
          if (onScreen && opacity === 1) {
            // Override CSS animations for a split second (only once though)
            c.classList.add("triggered");
            // Alternative technique of resetting animation
            // c.style.animation = "none";
            // Allow CSS animations to run again
            // setTimeout(() => {
            // c.style.animation = null;
            // }, 100);
          }
        }
      });
    }
  }, 250);
};
