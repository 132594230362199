export default {
  name: "Cancer in the workplace",
  modules: [
    "overview",
    "whatIsCancer",
    "surgery",
    "chemotherapy",
    "radiotherapy",
    "biotherapy",
    "psychologicalSideEffects",
    "supportingYourStaff",
    "cpd-certificate",
    "useful-links-and-sources",
  ],
  description:
    "This workshop walks you through modules looking at the physical and psychological side effects of cancer, and its treatment.",
};
