export default {
  name: "Mental Health",
  modules: [
    "whatIsMentalHealth",
    "whatLineManagersNeedToKnow",
    "theMentalHealthContinuum",
    "yourRoleAsLineManager",
    "howToSupportAnEmployee",
    "supportingAnAbsentEmployee",
    "supportingTheTeamAndYourself",
    "developingAStrategy",
    "cpd-certificate",
    "useful-links-and-sources",
  ],
  description:
    "This workshop is designed to give line managers the insight and confidence to recognise and support an employee who is distressed or experiencing a mental health condition.",
};
