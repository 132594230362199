import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-strategy.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Preventing sickness absence",
  description: `Strategies that help prevent sickness absence are key to both a healthy workforce and greater productivity.
  What steps can you take for a healthier workplace?`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Employee Engagement",
      "Tracking, Measuring & Monitoring",
      "Health & Wellbeing Initiatives", // client: "These can be on 3 lines"
      "Health & Safety",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Employee engagement</h3>
              <ul>
                <li>Begin at induction to reinforce company values</li>
                <li>Provide manager training — line managers are key</li>
                <li>
                  Top down, regular line manager engagement and communication
                </li>
                <li>
                  Reward and recognition programmes highlighting employees
                  performing well
                </li>
                <li>
                  Work surveys: Measure engagement, get feedback and identify
                  problem areas
                </li>
                <li>
                  Committees: Social, wellbeing, sports etc. that promote
                  employee involvement
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Tracking, measuring & monitoring</h3>
              <ul>
                <li>
                  Record causes of absence, including business area, dates, job
                  title etc.
                </li>
                <li>
                  Compare to previous records to understand trends and areas of
                  concern
                </li>
                <li>
                  When you first start measuring, rates may increase. True
                  absence rates were probably previously hidden
                </li>
                <li>
                  Calculate time/costs lost through absence to attain buy-in for
                  prevention and support strategies
                </li>
                <li>
                  Use data to benchmark within your industry and review outcomes
                  annually to measure success
                </li>
                <li>
                  Use a Wellness Action Plan for employees who have ongoing
                  health issues or face a likely recurrence of symptoms, which
                  involves regular meetings between employer and employee and an
                  employee-led strategy to manage their symptoms at work and
                  remain well.
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Health and wellbeing initiatives</h3>
              <ul>
                <li>Onsite gym or discounted gym memberships</li>
                <li>Cycle to work schemes, sports or fitness initiatives</li>
                <li>
                  Wellbeing seminars (e.g. stress management or resilience)
                </li>
                <li>Lifestyle advice (quitting smoking, weight loss etc.)</li>
                <li>
                  Employee Assistance Programme — supports employee health and
                  wellbeing across their personal and professional lives
                </li>
                <li>
                  Consider a Health and Wellbeing Review to examine your
                  workplace health and wellbeing strategies and make
                  recommendations for improvements
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Health & safety</h3>
              <ul>
                <li>
                  Embed as part of an overall prevention strategy — not just
                  standalone
                </li>
                <li>
                  Visible advice — information on posters, intranet, emails etc.
                </li>
                <li>
                  Appoint Safety Champions to promote a safe working environment
                </li>
                <li>
                  Training — ergonomic, stress management and manager training
                  (including bullying/harassment)
                </li>
                <li>Implement a Mental Health First Aid programme</li>
                <li>
                  Consider the Health & Safety Executive (HSE) management
                  standards
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
