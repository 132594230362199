import React, { Component } from "react";
import icons from "../../../components/Icons";

import SvgHandler from "../../../lib/SvgHandler";
// import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-bed.svg";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-conversation.svg";

import CheckVisibility from "../../../components/CheckVisibility";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Your role as a line manager",
  description: `The line manager can often be the first line of defence for an employee’s mental health. Here are some vital points to consider.`,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Reassurance", "Empathy", "Courage", "Understanding"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }

  render = () => (
    <>
      <div className="icon-row">
        <CheckVisibility>{icons.healthLayered}</CheckVisibility>

        <div>
          <h3> Establishing legitimacy </h3>
          <p>
            How do you tell if an employee is genuinely unwell? There may be a
            change in their behaviour or performance, though this may be harder
            to work out with a newer employee.
          </p>
          <p>
            Think about the employee’s issue in objective terms — the
            limitations it causes, or if they’ve seen their GP or asked for
            other support.
          </p>
        </div>
      </div>

      <div className="oblong -row-centered">
        <div className="text_wrapper">
          <h3>How much do you need to know about mental health?</h3>
          <p>
            You’re neither expected to be nor do you need to be a mental health
            expert or counsellor, but some background knowledge can help your
            confidence if faced with an employee working with mental health
            issue.
          </p>
          <p>
            Ensure you know your staff so you can pick up on any changes and
            understand what support services are available.
          </p>
        </div>
        <CheckVisibility>{icons.headLayered}</CheckVisibility>
      </div>
      <div className="icon-row">
        <CheckVisibility>{icons.stressLayered}</CheckVisibility>
        <div>
          <h3>Supporting yourself</h3>
          <p>
            Dealing with other people’s mental health can be difficult and
            emotive. Ensure you make use of the available services and resources
            yourself so you have the right support.
          </p>
        </div>
      </div>

      <h3>The skills required to have the conversation</h3>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Reassurance </h3>
              <ul>
                <li>Put the person at ease </li>
                <li>
                  Make it clear that the conversation is about their welfare and
                  problem-solving, and not disciplinary.
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Empathy</h3>
              <ul>
                <li>Listen, listen, listen</li>
                <li>Adopt a non-judgmental approach</li>
                <li>
                  Maintain boundaries — don’t feel that you have to be the
                  employee’s counsellor or therapist
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Courage</h3>
              <ul>
                <li>Don’t put the discussion off</li>
                <li>Try to have it face-to-face</li>
                <li>Manage your emotions </li>
                <li>Keep it professional</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Understanding </h3>
              <ul>
                <li>Describe your understanding of the issue</li>
                <li>Check at regular intervals during the conversation</li>
                <li>Agree the nature of the issue together</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
