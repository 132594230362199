import React, { Component } from "react";
import Video from "../../../components/Video";

const info = {
  title: "What is cancer?",
  description: `The body is made up of millions of cells. These cells group together to make up tissues and organs in the body. Normal cells constantly reproduce to replace dead or damaged ones.
  If a fault occurs in normal cell growth, the cells grow in an uncontrolled way and form tumours. These cells can spread through the body via the lymphatic system and the bloodstream.`,
};

export default class extends Component {
  static info = info;

  render = () => (
    <>
      <Video file="Emodule_CANCER_003_" />
      {/* <div className="refs">
        <cite>
          <ul>
            <li data-prefix="1. ">
              Source for video: 200 types stat, 4 out of 10 could be prevented
              stat, 2-3% inherited stats, signs and symptoms all{" "}
              <a
                href="https://www.cancerresearchuk.org/about-cancer"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.cancerresearchuk.org/about-cancer
              </a>
            </li>
          </ul>
        </cite>
      </div> */}
    </>
  );
}
