import { Link } from "react-router-dom";
import "./resource.scss";
export default ({ title, url }) => {
  // Attempt to get filetype from url string
  let fileType = "WEB";
  // Get last item from split array
  const fileTypeMaybe = url.split(".").pop();
  if (fileTypeMaybe.length === 3 || fileTypeMaybe.length === 4) {
    fileType = fileTypeMaybe;
  }
  if (
    fileTypeMaybe.includes("/") ||
    fileTypeMaybe === "html" ||
    fileTypeMaybe === "htm" ||
    fileTypeMaybe === "php"
  ) {
    fileType = "WEB";
  }
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="resource"
    >
      <span>
        {title}
        {/* {fileType && <small>{fileType}</small>} */}
      </span>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style={{ enableBackground: "new 0 0 38 38" }}
      >
        <style type="text/css">{`
	.st0{fill:#015294;}
	.st1{fill:#FFFFFF;stroke:#FFFFFF;}
	.st2{fill:none;stroke:#FFFFFF;}
`}</style>
        {fileType === "WEB" ? (
          <g transform="translate(419.000000, 20.000000)">
            <circle id="Oval" className="st0" cx="-400" cy="-1" r="19" />
            <path
              id="Path-3"
              className="st1"
              d="M-396.3-1h-11H-396.3v-3l2.7,3l-2.7,3V-1z"
            />
          </g>
        ) : (
          // <g transform="translate(419.000000, 20.000000)">
          //   <circle id="Oval" className="st0" cx="-400" cy="-1" r="19" />
          //   <path
          //     id="Path-3"
          //     className="st1"
          //     d="M-400-2.7v-5V-2.7h3l-3,2.7l-3-2.7H-400z"
          //   />
          //   <polyline
          //     id="Path-4"
          //     className="st2"
          //     points="-408,1 -408,4 -392,4 -392,1"
          //   />
          // </g>
          <g transform="translate(0, 20)">
            <circle id="Oval" className="st0" cx="19" cy="-1" r="19" />
            <path
              d="M25.5,7.5h-13V-8.1h9.1l3.9,3.9V7.5z M21-7.4h-7.8V6.9h11.7V-3.5H21V-7.4z M22.9,2.3V3h-7.8V2.3H22.9z
             M22.9,0.4V1h-7.8V0.4H22.9z M22.9-1.6v0.6h-7.8v-0.6H22.9z M21.6-4.2h3l-3-3V-4.2z"
              fill="#FFFFFF"
            />
            />
          </g>
        )}
      </svg>
    </a>
  );
};
