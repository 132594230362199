import React, { Component } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-shield-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Preventing MSDs",
  description: `Prolonged, awkward positions and repetitive movements can aggravate existing MSDs and even cause work-related MSDs. Weight, physical fitness, age and poor work practises could also contribute. However, prevention can be cost-effective and simple.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Evaluation", "Ergonomics", "Early intervention", "Education"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Evaluation</h3>
              <ul>
                <li>
                  Make an annual/monthly audit of absences to see how many are
                  MSD-related to identify any trends and need for further
                  training
                </li>
                <li>
                  Document your company’s policy and best practice to encourage
                  early reporting of MSDs
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Ergonomics</h3>
              <ul>
                <li>One size does NOT fit all</li>
                <li>Ensure everyone’s workstation is checked frequently</li>
                <li>
                  Teach employees to adjust workstation equipment and take
                  ownership
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Early intervention</h3>
              <ul>
                <li>Recognise early warning signs of MSDs</li>
                <li>
                  Consider temporary adjustments such as short, frequent breaks
                  and varying tasks
                </li>
                <li>Encourage employees to adapt self-management techniques</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Education</h3>
              <p>
                Identify high-risk factors through providing basic ergonomic
                education and employee training.
              </p>
              {/* Removed as no longer present in copy doc */}
              {/* <p>
                For more information, see{" "}
                <Link to={`/musculoskeletal-disorders/modules/6`}>
                  Module 6
                </Link>{" "}
                – Supporting an employee.
              </p> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}
