import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-bed.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Surgery",
  description: `Surgery can be used to find the location of the cancer, reduce the size, remove it completely or position tubes for treatment.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Myths", "Facts", "Side Effects", "Duration"],
  });
  componentDidMount() {
    this._isMounted = true;
    startGreensockAnimations();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <div className="myths">
              <h3>{info.title} myths</h3>
              <h5>Myth</h5>
              <p>
                Surgery is the simplest method and quickest method to treat
                cancer
              </p>
              <h5>Truth</h5>
              <p>
                Surgery is often the start of a long process to diagnose and
                treat cancer, not a one-time solution. For instance, surgery
                sometimes still needs following by chemo or radiotherapy.
              </p>
              <p>
                Also, many people can end up with significant changes to their
                bodies from removal of body parts, such as scarring or the need
                to maintain tubes and bags.
              </p>
            </div>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>{info.title} facts</h3>
              <ul>
                <li>Surgery is the main treatment for many types of cancer</li>
                <li>
                  Tissue and local lymph nodes are often removed during surgery
                  to assess risk of spread
                </li>
                <li>
                  Typically, surgery is used if the cancer is only in one area
                  of the body
                </li>
                <li>
                  People may also have surgery to reconstruct affected areas
                </li>
                <li>
                  Through surgery, a part of the cancer can be removed to
                  control other symptoms such as fits or pain
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>{info.title} side effects</h3>
              <ul>
                <li>
                  Inflammation which can lead to complications — especially if
                  the immune system is compromised
                </li>
                <li>
                  If a body part is removed, this can impact psychological
                  wellbeing
                </li>
                <li> Potential bruising or blood clots</li>
                <li>
                  Possible need for a surgical opening (stoma) to allow the exit
                  of stools or urine, which can impact lifestyle as well as
                  psychological wellbeing
                </li>
                <li>
                  Pain for a few weeks after surgery (although some can suffer
                  continuing pain, known as post-operative pain syndrome)
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>{info.title} duration</h3>
              <ul>
                <li>
                  Surgery time can range from 30 minutes to 12 hours depending
                  on cancer type, location and risk
                </li>
                <li>
                  Impact can be ongoing, with physical changes potentially being
                  permanent or long-term
                </li>
                <li> Some people will need multiple surgeries</li>
                <li>
                  Length of stay in hospital varies based on the type of surgery
                  and how quickly the person recovers
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
