import React, { Component } from "react";
export const info = {
  title: "CPD Certificate",
};
export const CpdCertificate = (props) => () => {
  return (
    <>
      <h2 className="shift-up">CPD Certificate</h2>
      <div className="wrapper">
        {props.content}
        <p>
          The next page contains useful links and sources for the topics covered
          in this e-module.
        </p>
        {props.cpdCertificateUrl && (
          <div className="">
            <p>
              Alternatively, obtain your CPD certificate by{" "}
              <a
                href={props.cpdCertificateUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                clicking here
              </a>
              .
            </p>
            <img
              src="/images/cpd-logo.svg"
              alt="CPD accreditation"
              width="150"
            />
          </div>
        )}
      </div>
    </>
  );
};
