import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-psych-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "What line managers need to know",
  description: `Less than a third (31%) of organisations believe managers are confident and competent in spotting the early warning signs of mental ill-health<sup>1</sup>.
  Yet early intervention can be key to preventing mental ill-health from becoming a long-term problem.`,
  showClickOnASubjectMessage: false,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Cognitive", "Physical", "Behavioural", "Emotional"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <p>
        The stigma that can surround mental health issues can stop employees
        from admitting they are struggling, so line managers need to know what
        to look for. This is especially true if there are significant changes to
        how they usually are or act.
      </p>
      <p>
        Click on a subject for more information.
        <br />
        <br />
      </p>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Cognitive</h3>
              <ul>
                <li>Easily distracted</li>
                <li>Making mistakes or forgetful</li>
                <li>Impaired judgment or decision-making</li>
                <li>Indecisive</li>
                <li>Self-critical or negative</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Physical</h3>
              <ul>
                <li>Increased tiredness</li>
                <li>Skin irritation or rashes</li>
                <li>Excessive sweating</li>
                <li>Palpitations</li>
                <li>Looking tired or unkempt</li>
                <li>Frequent colds or infections</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Behavioural</h3>
              <ul>
                <li>Works longer hours</li>
                <li>Doesn’t take tea or lunch breaks</li>
                <li>Avoids situations</li>
                <li>Often late or takes unofficial time off</li>
                <li>Not laughing or joining in conversations</li>
                <li>Not performing or achieving targets</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Emotional</h3>
              <ul>
                <li>Sensitive to criticism</li>
                <li>Tearful, moody, angry or irritable</li>
                <li>Anxious or nervous</li>
                <li>Demotivated or depressed</li>
                <li>Emotional outbursts or overreacting</li>
                <li>Loss of confidence and self-esteem</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
