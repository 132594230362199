export default {
  name: "Musculoskeletal disorders",
  modules: [
    "whatIsAnMSD",
    "whatAreCommonMSDs",
    "backPain",
    "preventingMSDs",
    "psychologicalImpactOfMSDs",
    "supportingAnEmployee",
    "cpd-certificate",
    "useful-links-and-sources",
  ],
  description:
    "This workshop walks you through modules looking at musculoskeletal disorders (MSDs), including downloadable tools and resources.",
};
