import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-feet-5.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Support if a colleague dies",
  description: `As a manager, there are several things to consider and steps to take when a colleague dies.`,
  showClickOnAStepMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <ul>
                <h3>First steps</h3>
                <li>
                  Notify staff as soon as possible — but only with the next of
                  kin’s consent (until the death is registered)
                </li>
                <li>Agree with next of kin what information can be shared</li>
                <li>
                  Contact anyone close to the deceased individually to make them
                  aware
                </li>
                <li>
                  Let people know the date and time of the memorial service and
                  possible charitable contributions
                </li>
                <li>Be aware of social media – news can travel fast</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>The physical impact on work </h3>
              <p>
                Depending on the family’s wishes, it may be appropriate for work
                colleagues to attend the funeral.
              </p>
              <h4>If so, try to understand:</h4>
              <ul>
                <li>How many colleagues can attend?</li>
                <li>What are the impacts on the business?</li>
              </ul>
              <p>
                Where possible, allow leave for people to go to the service as
                it helps to promote a sense of support and togetherness and may
                help colleagues process the bereavement.
              </p>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>The psychological impact on work</h3>
              <p>Be aware of what colleagues may feel at work:</p>
              <ul>
                <li>Anger</li>
                <li>Sorrow</li>
                <li>Fear</li>
                <li>Confusion</li>
                <li>Anxiety</li>
              </ul>
              <p>
                People may feel awkward and worry about saying or doing the
                wrong thing.
              </p>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Actions to take</h3>
              <ul>
                <li>
                  Arrange appropriate, sensitive delegation of work duties
                </li>
                <li>Retrieve voicemail and email messages</li>
                <li>Turn off the deceased’s voicemail and email account</li>
                <li>Decide how to respond to outside calls for information</li>
                <li>
                  Removal of personal effects can be a sensitive issue for
                  colleagues so consult any next of kin or close friends
                  beforehand
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>Emotional support at work</h3>
              <p>
                Achieving the right balance is the goal. While the death mustn’t
                be ignored, it’s important to recognise work must continue.
              </p>
              <ul>
                <li>Talking about the loss within the office can help</li>
                <li>
                  Consider a collective commemoration or event, or creating a
                  book of memories with staff for the colleague’s family
                </li>
                <li>Plan an activity to commemorate the colleague</li>
                <li>Meet with employees to check how they are coping </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
