import React, { Component } from "react";

export default class extends Component {
  // state = { beginPlaying: false };
  componentDidMount() {
    // setTimeout(() => {
    //   // this.setState({ beginPlaying: true });
    //   if (this.refs.video) {
    //     this.refs.video.play();
    //   }
    // }, 5500);
  }
  render = () => {
    // if (!this.state.beginPlaying) return null;
    return (
      <div className="video-wrapper">
        <video
          width="1280"
          height="720"
          controls
          ref="video"
          poster={`/videos/${this.props.file}.jpg`}
        >
          <source src={`/videos/${this.props.file}.mp4`} type="video/mp4" />
          <source src={`/videos/${this.props.file}.webm`} type="video/webm" />
          Your browser does not support videos
        </video>
      </div>
    );
  };
}
