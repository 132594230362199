import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-hands-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting an employee",
  description: `There are things you can do to help when an employee is absent from work due to MSD. A person doesn’t have to be fully recovered to return to work.
  Work tasks can provide some physical rehabilitation to help them recover.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Communication",
      "Early intervention",
      "Return to work",
      "Condition management",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "") +
          " shift-down"
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Communication</h3>
              <ul>
                <li>Ensure regular contact during an absence</li>
                <li>Agree frequency of contact and how — email/phone</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Early intervention</h3>
              <ul>
                <li>Seek return to work support services</li>
                <li>Employee Assistance Programme referral</li>
                <li>
                  <a
                    href="https://www.unum.co.uk/adviser/help-at-hand"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Help@hand
                  </a>{" "}
                  physiotherapy support
                </li>
                <li>Create a graded return-to-work plan</li>
                <li>
                  Consider temporary modification of duties:
                  <ul>
                    <li>Introduce short frequent breaks</li>
                    <li>Have walking meetings</li>
                    <li>Vary tasks</li>
                    <li>Ease of access to desk or workstation</li>
                  </ul>
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Return to work</h3>
              <ul>
                <li>
                  Coming back to work before complete resolution of symptoms can
                  be beneficial
                </li>
                <li>
                  Ensure pacing of work hours and duties (graduated
                  return-to-work plan)
                </li>
                <li>Offer flexibility for medical appointments</li>
                <li>Provide a full ergonomic assessment</li>
                <li>Undertake a risk assessment</li>
                <li>Access to work</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Condition management</h3>
              <ul>
                <li>Exercise breaks</li>
                <li>Hydration and diet</li>
                <li>Breathing exercises</li>
                <li>Healthy options in work canteen</li>
                <li>Pacing and activity planning</li>
                <li>Cognitive Behavioural Therapy (CBT) via GP</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
