import React, { Component } from "react";
import { winHeight } from "../lib/utilities";

export default class extends Component {
  state = { becameVisible: false };
  componentDidMount() {
    document.getElementById("root").addEventListener("scroll", () => {
      this.checkVisibility();
    });
    // Optimisation - this is, you know, unideal but it does clear the interval after use/unmount
    this.interval = setInterval(() => {
      this && this.checkVisibility();
    }, 250);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  checkVisibility() {
    // Check if it's on screen and also opaque - as in, any parent opacity animations
    if (!this.refs.me) return; // umounting..
    const { top, height } = this.refs.me.getBoundingClientRect(),
      onScreen = top < winHeight() && top + height > 0,
      opacity = window
        .getComputedStyle(this.refs.me)
        .getPropertyValue("opacity");
    if (onScreen && opacity > 0) {
      this.setState({
        becameVisible: true,
      });
      clearInterval(this.interval);
    }
  }
  render = () => {
    // Only render the content when the element becomes visible - that way it'll trigger any animations therein in a timely manner (i.e. after sequential content loading animations)
    return (
      <div ref="me">{this.state.becameVisible && this.props.children}</div>
    );
  };
}
