import React, { Component } from "react";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Resource from "../../../components/resource";
import { OtherModulesLink } from "../../../components/OtherModulesLink";
const info = {
  title: "Useful links and sources",
  description: ``,
};
export default class extends Component {
  static info = info;
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h2 className="shift-up">What is mental health?</h2>
      <div className="resources">
        {[
          {
            title: "Mind: Types of mental health problems",
            url: "https://www.mind.org.uk/information-support/types-of-mental-health-problems/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>What line managers need to know</h2>
      <div className="resources">
        {[
          {
            title: "1. CIPD Health and Wellbeing at Work Survey 2021",
            url: "https://www.cipd.co.uk/Images/health-wellbeing-work-report-2021_tcm18-93541.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Your role as a line manager</h2>
      <div className="resources">
        {[
          {
            title: "Wellness plan",
            url: "https://www.unum.co.uk/docs/UP4234.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Supporting your team and yours</h2>
      <div className="resources">
        {[
          {
            title: "Unum’s Mental Health Pathway",
            url: "https://www.unum.co.uk/workplace-wellbeing-hub/mental-health-pathway",
          },
          {
            title:
              "Mental health support from qualified mental healthcare professionals",
            url: "https://www.unum.co.uk/employer/help-at-hand",
          },
          {
            title: "Mental Health Foundation: Podcasts",
            url: "https://www.mentalhealth.org.uk/podcasts-and-videos/podcasts-for-your-wellbeing",
          },
          {
            title: "Mind: Mental health at work",
            url: "https://www.mind.org.uk/workplace/mental-health-at-work/taking-care-of-yourself/tips-for-employees/",
          },
          {
            title: "Mind: 5 ways to wellbeing",
            url: "https://www.mind.org.uk/workplace/mental-health-at-work/taking-care-of-yourself/five-ways-to-wellbeing/",
          },
          {
            title:
              "On Course: Unum’s workplace wellbeing workshops (employer workshops are CPD-accredited)",
            url: "https://www.unum.co.uk/workplace-wellbeing-hub/workshops",
          },
          {
            title:
              "Wellbeing Calendar: Packed with useful resources to support workplace wellbeing",
            url: "https://www.unum.co.uk/wellbeing-calendar",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Sources and further reading</h2>
      <div className="resources">
        {[
          {
            title: "NHS",
            url: "https://www.nhs.uk/conditions/stress-anxiety-depression/",
          },
          {
            title: "Samaritans",
            url: "https://www.samaritans.org/how-we-can-help/contact-samaritan/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <OtherModulesLink />
    </>
  );
}
