import React, { Component } from "react";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Resource from "../../../components/resource";
import { OtherModulesLink } from "../../../components/OtherModulesLink";
const info = {
  title: "Useful links and sources",
  description: ``,
};
export default class extends Component {
  static info = info;
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h2 className="shift-up">Preventing sickness absence</h2>
      <div className="resources">
        {[
          {
            title: "What is a Health and Wellbeing Review?",
            url: "https://www.unum.co.uk/workplace-wellbeing-hub/mental-health-pathway/review",
          },
          {
            title: "Wellness Action Plan",
            url: "https://www.unum.co.uk/docs/UP4233.pdf",
          },
          {
            title: "Wellness Action Plan template",
            url: "https://www.unum.co.uk/docs/UP4234.pdf",
          },
          {
            title: "Wellbeing workshops",
            url: "https://www.unum.co.uk/workplace-wellbeing-hub/workshops",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Supporting an employee during absence</h2>
      <div className="resources">
        {[
          {
            title: "Writing to your employee’s GP",
            url: "http://online.positiveimagesuk.com/unum/download/UP1328.pdf",
          },
          {
            title: "Access to work",
            url: "https://www.gov.uk/access-to-work",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Supporting an employee’s return to work</h2>
      <div className="resources">
        {[
          {
            title: "Interview template",
            url: "http://online.positiveimagesuk.com/unum/download/UP3292.pdf",
          },
          {
            title: "Creating a graduated return to work (GRTW) plan",
            url: "http://online.positiveimagesuk.com/unum/images/UP4232.pdf",
          },
          {
            title: "6-week GRTW plan",
            url: "https://www.unum.co.uk/docs/UP4231.pdf",
          },
          {
            title: "8-week GRTW plan",
            url: "https://www.unum.co.uk/docs/UP4230.pdf",
          },
          {
            title: "12-week GRTW plan",
            url: "https://www.unum.co.uk/docs/UP4229.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <OtherModulesLink />
    </>
  );
}
