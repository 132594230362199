import React from "react";
import { isUrl } from "../lib/utilities";
export default ({ showTitle = true, refs, fontSize }) => (
  <div className="refs">
    {showTitle && <h3>Refs</h3>}
    <cite>
      <ul style={{ fontSize }}>
        {refs.map((r, i) => {
          let url, text;
          if (typeof r === "string") {
            url = r;
            text = r;
          } else {
            url = r.url;
            text = r.text;
          }
          return (
            <li key={i}>
              {isUrl(url) ? (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {text}
                </a>
              ) : (
                text
              )}
            </li>
          );
        })}
      </ul>
    </cite>
  </div>
);
