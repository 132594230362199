import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-health.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";
const info = {
  title: "Chemotherapy",
  description: `Chemotherapy uses anti-cancer drugs to destroy cancer cells. The drugs circulate through the body in the bloodstream, so chemotherapy can treat cancer cells almost anywhere.`,
  showClickOnASubjectMessage: true,
};
export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Myths", "Facts", "Side Effects", "Duration"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <div className="myths">
              <h3>{info.title} myths</h3>
              <h5>Myth</h5>
              <p>
                Having chemotherapy means you can’t live at home or go to work.
              </p>
              <h5>Truth</h5>
              <p>
                Most people are treated on an outpatient basis and some continue
                to work.
              </p>
              <h5>Myth</h5>
              <p>Chemotherapy kills cancer cells only.</p>
              <h5>Truth</h5>
              <p>
                Chemotherapy inhibits rapid cell growth by attacking all cells
                at their core (including healthy cells).
              </p>
              <h5>Myth</h5>
              <p>There is only one type of chemotherapy.</p>
              <h5>Truth</h5>
              <p>Treatment cycles are customised to the individual.</p>
              <h5>Myth</h5>
              <p>Everybody loses their hair.</p>
              <h5>Truth</h5>
              <p>Hair loss is less likely with newer drugs.</p>
              <h5>Myth</h5>
              <p>Chemotherapy is painful.</p>
              <h5>Truth</h5>
              <p>
                The treatment itself is almost never painful, but side effects
                can vary from person to person.
              </p>
            </div>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>{info.title} facts</h3>
              <ul>
                <li>
                  A cell-killing drug treatment (kills cells as they divide)
                </li>
                <li>There are over 100 chemotherapy drugs</li>
                <li>Can be used on its own or to support another treatment</li>
                <li>
                  Administered via oral tablets, injection or intravenously
                </li>
                <li>
                  Can cure some types of cancer by destroying all cancerous
                  cells
                </li>
                <li>
                  Can reduce the risk of cancer returning following surgery
                </li>
                <li>
                  Can shrink the size of a cancer and prolong a person’s life if
                  a cure is impossible
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>{info.title} side effects</h3>
              <p>The most common side effects are:</p>
              <ul>
                <li>Fatigue</li>
                <li>Nausea</li>
                <li>High risk of infection</li>
                <li>Possible nerve damage</li>
                <li>Mouth ulcers</li>
                <li>Hair, skin and nail changes</li>
                <li>Reduced sex drive</li>
              </ul>
              <p>
                Most side effects are short-term but can last longer depending
                on the type of drug and dosage. Keep in mind that:
                <br />
              </p>
              <ul>
                <li>
                  Chemotherapy kills rapidly dividing cells. These are cells
                  that promote growth, which is why hair can fall out.
                </li>
                <li>
                  ‘Chemo brain’ is a common term used by people with cancer
                  having chemotherapy to describe cognitive problems (thinking
                  and memory problems), which can last for many years
                </li>
                <li>
                  Long-term effects of chemotherapy can be infertility, early
                  menopause and damage to the nervous system
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>{info.title} duration</h3>
              <ul>
                <li>
                  A course usually lasts 3-6 months in 1-4 or 6-weekly cycles
                </li>
                <li>Sometimes continuous to reduce risk of recurrence</li>
                <li>
                  Length, amount and type of chemotherapy depends on the type
                  and stage of the cancer, as well as the health and fitness of
                  the person
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
