import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-hands-3b.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting the team and yourself",
  description: `Ensuring good mental health in the workplace can be difficult and emotive. It needs planning, time and the right support — for everyone.`,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Monitoring absence",
      "Supporting the team",
      "Look after yourself",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Monitoring absence</h3>
              <ul>
                <li>
                  Monitoring and measuring absence is key to identifying trends
                </li>
                <li>
                  Use the results to help you plan in supporting employees to
                  stay at work
                </li>
                <li>
                  It can track when you last spoke to the employee and can help
                  you plan and potentially prevent a long-term absence
                </li>
                <li>
                  Monitoring absence doesn’t need to be complex — if your
                  business doesn’t have a system, free, easy to use tools are
                  available online to help you capture the essential information
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Supporting the team</h3>
              <ul>
                <li>
                  Consider how the team will be or are being impacted — is extra
                  support needed to manage workload?
                </li>
                <li>
                  Consider the emotional impact. They may be worried about their
                  colleague or friend
                </li>
                <li>
                  Ensure clear communication and openness — signpost them to
                  your EAP if available and be approachable as a manager
                </li>
                <li>
                  Discuss how much the employee is comfortable sharing with the
                  team and how to go about it
                </li>
                <li>
                  Discuss with the team how they can approach and support the
                  individual
                </li>
                <li>Address the ‘elephant in the room’</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Look after yourself</h3>
              <p>
                Manage an employee with recurring mental health problems can be
                challenging
              </p>
              <ul>
                <li>Don’t keep it to yourself</li>
                <li>
                  Consider whether there’s any training, coaching or mentoring
                  you would find helpful
                </li>
                <li>Identify someone you can debrief, including HR</li>
                <li>
                  Don’t get drawn into giving advice on personal situations
                </li>
                <li>Contact your EAP (if you have one) for manager support</li>
                <li>Don’t lose sight of your own mental health</li>
                <li>
                  Be approachable, observant, knowledgeable and good at
                  listening
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
