import { info, CpdCertificate } from "../../cpd-certificate";
const _module = CpdCertificate({
  cpdCertificateUrl: "https://www.brainshark.com/unumuk/MSDtest",
  content: "",
});
_module.info = {
  ...info,
  description: `You have now completed our <em>Musculoskeletal disorders</em> e-module. We hope you found it useful and now feel more informed about the impact of musculoskeletal problems at work.`,
};
export default _module;
