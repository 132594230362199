import React, { Component } from "react";
import Video from "../../../components/Video";

const info = {
  title: "What is mental health?",
  description: `Having good mental health means being in a state of wellbeing where we realise our own abilities, can cope with the normal stresses of life, work productively and contribute to our community.
  Just as we all have physical health, everybody has mental health too. That means we all need to care for our minds just as much as we care for the health of our bodies.
  `,
};

export default class extends Component {
  static info = info;
  render = () => (
    <>
      <Video file="Emodule_MENTAL_HEALTH_004_" />
      {/* <Refs refs={["https://www.cancerresearchuk.org/about-cancer"]} /> */}
      {/* <div className="refs">
        <cite>
          <ul>
            <li data-prefix="1. ">
              Source for video: 200 types stat, 4 out of 10 could be prevented
              stat, 2-3% inherited stats, signs and symptoms all{" "}
              <a
                href="https://www.cancerresearchuk.org/about-cancer"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.cancerresearchuk.org/about-cancer
              </a>
            </li>
          </ul>
        </cite>
      </div> */}
    </>
  );
}
