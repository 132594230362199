export default {
  name: "Stress awareness",
  modules: [
    "whatIsStress",
    "whyManageStress",
    "recognisingStress",
    "covid19AndStress",
    "managingStressRelatedProblems",
    "yourRoleAsALineManager",
    "keepingYouAndYourTeamHealthy",
    "cpd-certificate",
    "useful-links-and-sources",
  ],
  description:
    "This workshop walks you through modules looking at the physical and psychological effects of stress, and the importance of looking after yourself and your staff.",
};
