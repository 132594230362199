import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-feet-5.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Refs from "../../../components/Refs";

const info = {
  title: "How to support an employee",
  description: `Planning is key in supporting an employee with a mental health issue. Here’s a simple 5-step process that can be repeated as needed.`,
  showClickOnAStepMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Cognitive", "Physical", "Behavioural", "Emotional"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Get prepared</h3>
              <ul>
                <li>Make time</li>
                <li>Prepare opening thoughts and questions</li>
                <li>Be clear about the cause for concern</li>
                <li>Acknowledge how difficult it might be to open up</li>
                <li>
                  Don’t focus on or encourage them to give details about
                  specific injuries or behaviours — instead talk about how they
                  are feeling and what they are going through.
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Understand issues</h3>
              <ul>
                <li>
                  Aim only to understand what’s going on for them and how it
                  affects work
                </li>
                <li>Listen, listen, listen </li>
                <li>Be open and honest, and don’t judge</li>
                <li>
                  Don’t feel you need to have all the answers, but help them to
                  seek support
                </li>
                <li>
                  Focus on the person behind the behaviour and avoid giving
                  ultimatums
                </li>
                <li>Remind them you are there for support</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Identify resources</h3>
              <p>
                Is immediate support required? If so, consider pointing the
                employee towards:
              </p>
              <ul>
                <li>Mental Health First Aid</li>
                <li>GP</li>
                <li>Community mental health team</li>
                <li>Family/friends</li>
                <li>Unum referral for rehab support</li>
                <li>Occupational health</li>
                <li>Employee Assistance Programme (EAP)</li>
                <li>
                  Mental health support from qualified mental healthcare
                  professionals*
                </li>
              </ul>
              {/* Leave this <Refs> as it's legit, see #3518 */}
              <Refs
                showTitle={false}
                fontSize=".8em"
                refs={[
                  `*Help@hand is provided to Unum customers by third party specialists chosen by Unum. Help@hand does not form part of any Unum insurance policy. It is subject to the terms and conditions of the relevant third-party provider. Help@hand can be withdrawn or changed by Unum at any time. There is no additional cost or increase in premium as a result of Unum making this benefit available.`,
                ]}
              />
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Agree and implement action plan</h3>
              <ul>
                <li>Is it appropriate for the employee to stay at work? </li>
                <li>
                  Have an open conversation about support, treatment, medication
                  etc.
                </li>
                <li>
                  Could you make changes to support the employee, e.g. flexible
                  hours, amended duties?
                </li>
                <li>Who will make the changes and when by?</li>
                <li>When will you review?</li>
                <li>Who should employee contact if they have concerns?</li>
                <li>How will plans be communicated to colleagues?</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>Review</h3>
              <ul>
                <li>Don’t expect to get it all right first time</li>
                <li>Decide a date for a review</li>
                <li>
                  If an adjustment hasn’t helped, don’t persevere with it —
                  equally, don’t let adjustments run for years unnecessarily
                </li>
                <li>Discuss alternative adjustments</li>
                <li>
                  Ensure all parties are happy and agree that wellbeing has been
                  sustainably resolved before concluding
                </li>
                <li>Understand issues (repeat Step 2 if appropriate)</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
