import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-managing-procon-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Your role as a line manager",
  description: `The role of the line manager can impact directly on how people feel — both positively and negatively. So how can line managers help prevent stress?`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      // No time to set labels at this late stage; come back to it if we reuse graphic
      // "Reduce employee stress",
      // "Add to employee stress",
      // "Managing difficult situations",
      // "Managing the individual",
      // "Managing & communicating",
      // "Respectful & responsible"
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Managers can help reduce employee stress by:</h3>
              <ul>
                <li>Communicating clearly and timely</li>
                <li>Being flexible</li>
                <li>Trusting in an employee's skills</li>
                <li>Giving positive feedback when justified</li>
                <li>Being a role model in managing their own stress</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Managers can directly add to employee stress by:</h3>
              <ul>
                <li>Not clearly explaining expected roles in projects</li>
                <li>Micromanaging</li>
                <li>Being bureaucratic</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Managing difficult situations</h3>
              <ul>
                <li>
                  Managing conflict: Deals with conflict fairly and promptly
                </li>
                <li>
                  Uses resources: Seeks advice from managers, HR or Occupational
                  Health when needed
                </li>
                <li>
                  Takes responsibility: Is supportive, responsible and
                  reasonable
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Managing the individual</h3>
              <ul>
                <li>Accessible: Available to talk personally</li>
                <li>
                  Sociable: Relaxed approach, socialising and using humour where
                  appropriate
                </li>
                <li>
                  Empathic: Looks to understand the employee, their motivation,
                  point of view and work/life balance
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>Managing and communicating workloads</h3>
              <ul>
                <li>
                  Proactive work management: Monitors and reviews current work,
                  allowing future prioritisation/planning
                </li>
                <li>
                  Problem-solving: Deals with problems promptly, rationally and
                  responsibly
                </li>
                <li>
                  Involved and empowering: Listens and consults, provides
                  direction, autonomy and development opportunities
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "5" && (
            <>
              <h3>Respectful and responsible</h3>
              <ul>
                <li>Integrity: Respectful and honest to employees</li>
                <li>Managing emotions: Is calm and confident</li>
                <li>
                  Considerate approach: Thoughtful when managing and delegating
                </li>
              </ul>
              <p>
                Remember, people often leave their line manager rather than
                their job.
              </p>
            </>
          )}
        </div>
      </div>
      {/* <Refs refs={["http://hse.gov.uk/research"]} /> */}
    </>
  );
}
