import React, { Component } from "react";
import { Pie } from "../../../components/Pie";
import icons from "../../../components/Icons";
import Oblong from "../../../components/Oblong";

const info = {
  title: "Why manage stress?",
  description: `Employers with more than five employees are required by law to carry out a risk assessment and act on it to protect employees from stress.`,
};

export default class extends Component {
  static info = info;

  render = () => (
    <>
      <p>
        Yet despite this, only 65% of these companies have carried out this risk
        assessment or stress audit.<sup>1</sup>
      </p>
      <p>
        This is a problem because there’s a strong correlation between an
        increase in stress-related absence and a rise in common mental health
        conditions.
      </p>
      <h3>Plus, stress can result in:</h3>
      <ul>
        <li>Sickness absence</li>
        <li>Employee turnover</li>
        <li>Poor performance</li>
        <li>Presenteeism (working while ill)</li>
      </ul>
      {/* Removed loads as no longer present in copy doc */}
    </>
  );
}
