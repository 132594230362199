import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-psych-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Recognising stress",
  description: `Stress can appear in different ways in different people, but there are a few common signs that can point to a stressed employee.
  The key is spotting changes in their normal behaviour.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Cognitive", "Physical", "Behavioural", "Emotional"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Cognitive</h3>
              <ul>
                <li>Easily distracted</li>
                <li>Making mistakes or forgetful</li>
                <li>Impaired judgment</li>
                <li>Indecisive</li>
                <li>Self-critical or negative</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Physical</h3>
              <ul>
                <li>Increased tiredness</li>
                <li>Skin irritation or rashes</li>
                <li>Excessive sweating</li>
                <li>Palpitations</li>
                <li>Looking tired or unkempt</li>
                <li>Frequent colds or infections</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Behavioural</h3>
              <ul>
                <li>Works longer hours</li>
                <li>Doesn’t take tea or lunch breaks</li>
                <li>Avoids situations</li>
                <li>Often late</li>
                <li>Takes unofficial time off</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Emotional</h3>
              <ul>
                <li>Sensitive to criticism</li>
                <li>Tearful, moody, angry or irritable</li>
                <li>Anxious/nervous</li>
                <li>Demotivated or depressed</li>
                <li>Emotional outbursts or overreacting</li>
                <li>Loss of confidence/self-esteem</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
