// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";

export default class extends Component {
  componentDidMount() {
    // Regularly measure the height of this element and send it as a window message
    // UPDATE: This isn't currently used, as we have decided to set the iFrame to always fill viewheight, but leave here if we tweak the iFrame script later
    // UPDATE: Let's do both! Because! If we set iFrame as 100vh with a scrollbar, that's fine unless the site behind it aaalso has a scrollbar. Then you get 2 of the buggers!
    // UPDATE: We believe iFrames are no longer being use but.. it is plausible so leave this as-is
    setInterval(() => {
      if (this.refs.this) {
        const tdIframeHeightFromWithin = this.refs.this.clientHeight;
        if (tdIframeHeightFromWithin) {
          window.parentSource &&
            window.parentSource.postMessage({ tdIframeHeightFromWithin }, "*");
        }
      }
    }, 300);
  }
  render() {
    return (
      <div
        ref="this"
        className={this.props.className ? `-${this.props.className}` : ""}
      >
        {this.props.children}
      </div>
    );
  }
}
