import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Site from "./pages/Site";
// import DownloadResources from "./pages/DownloadResources";
import Module from "./pages/Module";
import "./App.scss";

export default class extends Component {
  render() {
    return (
      <ScrollToTop>
        <Routes>
          <Route
            exact
            path="/:site/modules/:index"
            element={<Module {...this.props} />}
          />
          <Route exact path="/:site" element={<Site {...this.props} />} />
        </Routes>
      </ScrollToTop>
    );
  }
}
