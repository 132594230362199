import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-feet-5.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting your staff",
  description: `Planning and communication are key to assisting someone to return to work and stay at work before, during and after diagnosis and treatment.
  It’s important that support is tailored to each individual.`,
  showClickOnAStepMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Communication is Key",
      "Early Intervention",
      "Support",
      "Managing Fatigue",
      "Returning to Work",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Communication is key</h3>
              <p>
                When you find out about a diagnosis, it's important to meet with
                the employee to discuss next steps as each person will have
                individual needs. Remember:
              </p>
              <ul>
                <li>Be sensitive — it's a difficult conversation</li>
                <li>Ask them how they feel and what they need from you</li>
                <li>
                  Reassure them, and remind them they are valued — they need
                  your support
                </li>
                <li>Listen, listen, listen</li>
                <li>Ask how much they want to share with colleagues</li>
                <li>
                  Keep communications open — this is crucial to ensure a
                  successful outcome for both of you
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Early intervention</h3>
              <p>
                Support employees wanting to work before, during or after their
                treatment with:
              </p>
              <ul>
                <li>Flexible working</li>
                <li>Reduced hours</li>
                <li>Alternative days</li>
                <li>Home working</li>
                <li>Modified duties for a fixed period</li>
                <li>Reduced targets</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Plan a graduated return to work</h3>
              <p>This might involve:</p>
              <ul>
                <li>Work shadowing</li>
                <li>Working with a buddy</li>
                <li>Support with back office administration</li>
              </ul>
              <h4>Suitable adjustments may be required, such as:</h4>
              <ul>
                <li>Workstation equipment</li>
                <li>Fatigue management advice</li>
                <li>Time off to attend appointments</li>
                <li>Monitoring and reviewing progress</li>
                <li>Ongoing discussion</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Managing fatigue</h3>
              <p>Talk to your employee about:</p>
              <ul>
                <li>Having a regular diet and exercise routine</li>
                <li>Making the most of rest periods</li>
                <li>Counselling and support</li>
                <li>Planning the day so they have more time</li>
                <li>Planning a gradual return to work with reduced hours</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>Support</h3>
              <p>
                Be aware that returning to work is not the end of the journey.
                It can be just the start, so:
              </p>
              <ul>
                <li>Carefully consider what support you offer</li>
                <li>Provide a dedicated contact to liaise with all parties</li>
                <li>Work collaboratively</li>
                <li>
                  Discuss any further needs, such as psychological support
                </li>
                <li>Keep communicating throughout</li>
                <li>
                  Ensure any return to work support is:
                  <ul>
                    <li>Appropriate</li>
                    <li>Timely</li>
                    <li>Sustainable</li>
                  </ul>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
