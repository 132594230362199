class SvgHandler {
  get active() {
    return this._active;
  }
  set active(active) {
    this._active = active;
    // Run callback after setting active
    this.didUpdate && this.didUpdate(this._active);
  }
  get didUpdate() {
    return this._didUpdate;
  }
  set didUpdate(func) {
    // When receiving a new callback function, reset the active state
    this.active = null;
    this._didUpdate = func;
  }
  setSvgs(svgs, labels, lineLengthMax) {
    this._svgs = svgs.map((svg) => parseSvg(svg, labels, lineLengthMax));
  }
  get svgs() {
    return this._svgs;
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
}
const chunk = (string, lineLengthMax) => {
  let lineLength = 0;
  let chunk = string.split(" ").reduce((a, w, i) => {
    // console.log(lineLength, w.length, w);
    if (lineLength + w.length >= lineLengthMax && i > 0 && w !== "") {
      lineLength = 0;
      w = "^" + w;
    } else if (i > 0) {
      w = " " + w;
    }
    lineLength += w.length;
    return a + w;
  }, "");
  return chunk.split("^");
};
// Parse SVG content allowing us to add properties that won't get stripped out by Illustrator
const parseSvg = (svg, labels, lineLengthMax) => {
  // xlink:onClick -> onClick
  svg = svg.replace(/xlink:onClick/g, `onClick`);
  // xlink:setActive="three" -> onClick="window.svgHandlerSetActive('three')"
  svg = svg.replace(/xlink:setActive="[^"]*"/g, (match) => {
    let target = match.slice(`xlink:setActive="`.length, -1);
    return `class="hotspot hotspot--${target}" data-hotspot=${target} onClick="event.preventDefault();window.svgHandlerSetActive('${target}')"`;
  });
  // Replace any {LABEL2} style strings in the SVG if provided
  labels &&
    labels.forEach((label, i) => {
      // While we're at it, chunk up the string into <tspan>s with multilines when too wide, e.g.
      // <tspan>Short Label</tspan>
      // <tspan>Longer Label</tspan><tspan x="0" y="1em">Splits Like So</tspan>
      let words = label.split(" "),
        lineLength = 0,
        lineCount = 0,
        lineSpreadFactor = 1.1,
        // numLines = label.match(/.{1,13}/g).length,
        numLines = chunk(label, lineLengthMax).length,
        verticalCenter = true;
      // console.log(label, numLines);
      // Similar to chunk function above tbh, just haven't the time to unify it all
      let chunked =
        words.reduce((a, w, i) => {
          let brk = "";
          if (lineLength + w.length >= lineLengthMax && i > 0) {
            lineCount++;
            // NB: the first line is added further down, after this reduce
            let offset = verticalCenter
              ? (lineCount - (numLines - 1) / 2) * lineSpreadFactor
              : lineCount;
            brk = `</tspan><tspan x="0" y="${offset}em">`;
            lineLength = 0;
          } else if (i > 0) {
            w = " " + w;
          }
          lineLength += w.length + 1;
          return `${a}${brk}${w}`;
        }, `<tspan x="0" y="${verticalCenter ? (0 - (numLines - 1) / 2) * lineSpreadFactor : 0}em">`) +
        "</tspan>";
      svg = svg.replace(`{LABEL${i}}`, chunked);
    });
  // Remove ALL font-family declarations as they're never gonna be websafe. we'll specify with CSS
  svg = svg.replace(/font-family="[^"]*"/g, "");
  return svg;
};
const addHotspotsToSvg = (svg, hotspots) => {
  const hotspotsMarkup = hotspots
    .map((h, i) => {
      return `
      <a xlink:setActive="${i}" transform="translate(${h.x} ${h.y})">
        <g class="point" transform-origin="24 24">
          <path fill="white"  class="st2" d="M24,0C11,0,0,11,0,24s11,24,24,24s24-11,24-24S37,0,24,0z"/>
          <path data-fill="theme" d="M24,0C11,0,0,11,0,24s11,24,24,24s24-11,24-24S37,0,24,0z M24,41c-9,0-17-8-17-17S15,7,24,7s17,8,17,17S33,41,24,41z"/>
          <circle data-fill="theme" cx="24" cy="24" r="10"/>
        </g>
     </a>`;
    })
    .join("");
  return svg.replace("</svg>", hotspotsMarkup);
};
// Create a singular instance of this class
const svgHandler = new SvgHandler();
// Window based set event - run from the SVG <a> element onClicks
window.svgHandlerSetActive = (active) => {
  svgHandler.active = active;
};
// Let parent component update its svgActive state when this SVG updates
// Very much an anti-pattern, but lets our module components be compact AF
svgHandler.initState = ({
  svgs,
  context,
  labels = [],
  lineLengthMax = 13, // Wanting to increase this? Don't do it here; do it with within your page's initState
  hotspots = [],
  // scrollToSelectorOnClick,
}) => {
  if (hotspots) {
    // This doesn't handle multiple SVGs anymore, but they never got used anyway
    svgs[0] = addHotspotsToSvg(svgs[0], hotspots);
  }
  svgHandler.setSvgs(svgs, labels, lineLengthMax);
  setTimeout(() => {
    // Client no longer wants first hotspot to be set as active by default
    // context.setState({ svgActive: "0" });
  }, 100);
  svgHandler.didUpdate = (svgActive) => {
    if (svgActive) {
      const hotspot = hotspots?.[svgActive];
      if (hotspot?.headerChange) {
        document.getElementById("headerChange").innerHTML =
          hotspot.headerChange;
      }
      context.setState({ svgActive });
      const element = document.querySelector(".graphic_text");
      // if (scrollToSelectorOnClick)
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };
  return {};
};
// Export the single created instance of above class
// (so that whenever imported, it uses this one)
export default svgHandler;
