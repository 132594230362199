import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-ekg.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Radiotherapy",
  description: `Radiotherapy can be used on its own or with other treatments as a potential cure, to reduce a tumour before surgery or to destroy small tumours following surgery.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Myths", "Facts", "Side Effects", "Duration"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <div className="myths">
              <h3>{info.title} myths</h3>
              <h5>Myth</h5>
              <p>Radiotherapy is not used in isolation to treat cancer.</p>
              <h5>Truth</h5>
              <p>
                Radiotherapy can be a successful treatment when used in
                isolation.
              </p>
              <h5>Myth</h5>
              <p>There are always long-lasting side effects.</p>
              <h5>Truth</h5>
              <p>
                Side effects, while local to the treated area, usually begin to
                ease after the treatment is completed.
              </p>
              <h5>Myth</h5>
              <p>The treatment is painful.</p>
              <h5>Truth</h5>
              <p>
                There is usually a light warming or tingling sensation. Delivery
                to the head may require the use of a head brace.
              </p>
              <h5>Myth</h5>
              <p>Radiotherapy makes people become radioactive.</p>
              <h5>Truth</h5>
              <p>
                If you have external radiotherapy, where a machine aims beams of
                radiation at the cancer from outside your body, you will not
                become radioactive as the radiation passes through your body.”
              </p>
              <p>
                However, the radiation from implants or injections can stay in
                your body for a few days, so you may need to stay in hospital
                and avoid close contact with other people for a few days as a
                precaution.
              </p>
            </div>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Radiotherapy</h3>
              <ul>
                <li>Is a localised cell-killing treatment</li>
                <li>
                  Can be used to destroy and possibly cure a cancer (curative
                  radiotherapy)
                </li>
                <li>
                  Can be used to relieve cancer symptoms (palliative
                  radiotherapy) multiple times
                </li>
              </ul>
              <p>The main types of radiotherapy are:</p>
              <h4>External radiotherapy</h4>
              <ul>
                <li>
                  A high energy beam is precisely aimed at the site of your
                  cancer which damages the DNA within the cancer cells as it
                  passes through so they stop growing or die
                </li>
              </ul>
              <h4>Internal radiotherapy</h4>
              <p>
                Where radiation acts from within the body to damage cancer cells
                so they stop growing or kill them entirely. This might be:
              </p>
              <ul>
                <li>
                  Brachytherapy: Where implants of radioactive metal near the
                  site of the cancer provide a slow release of a localised dose
                </li>
                <li>
                  Radioisotope/Systemic Radiation Therapy: Where a radioactive
                  substance is given as injections, capsules or drinks to reach
                  a specific tissue via the bloodstream
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>{info.title} side effects</h3>
              <p>
                Dependent on the area being treated and dosage. Some people can
                work through the treatment.
              </p>
              <ul>
                <li>Tiredness and weakness</li>
                <li>Problems with eating and drinking</li>
                <li>Skin reactions</li>
                <li>Flu-like symptoms</li>
                <li>Hair loss</li>
                <li>Changes in the blood</li>
                <li>Nausea, vomiting or diarrhoea</li>
                <li>Stiff joints and muscles</li>
                <li>Reduced sex drive</li>
              </ul>
              <p>
                People may experience short-term, intermediate and long-term
                side effects. Some people have longer-lasting side effects after
                treatment due to the location of their cancer.
              </p>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>{info.title} duration</h3>
              <h4>External radiotherapy</h4>
              <ul>
                <li>
                  Treatment plans vary but many people have treatment 5 days a
                  week with rest at weekends over a number of weeks
                </li>
                <li>
                  Palliative radiotherapy may involve 1 or 2 sessions of
                  treatment to manage symptoms
                </li>
                <li>
                  Side effects may increase for a few weeks following completion
                  of treatment
                </li>
                <li>
                  Longer-term side effects often depend on the position of the
                  cancer
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
