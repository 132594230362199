import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-leaf-5.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Resilience: Keeping you and your team healthy",
  description: `Resilience in people is not necessarily black or white. Rarely is a person resilient or not resilient. Their resilience will depend on the circumstances, what the situation demands, their personality and any training or development they have had.
  The Robertson Cooper model below shows how four vital factors are important for a person’s resilience and help them manage stress.
`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    // Put these in if we need to re-use the graphic
    labels: [
      "Resilience",
      "Decisive",
      "Adaptable",
      "Social support",
      "Confidence",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Decisive</h3>
              <ul>
                <li>Having a clear sense of purpose</li>
                <li>Clear values</li>
                <li>Having drive and direction</li>
                <li>Persisting and achieving following setbacks</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Adaptable</h3>
              <ul>
                <li>Taking criticism on board</li>
                <li>Flexible and adaptable to changing situations</li>
                <li>Coping with change</li>
                <li>Bouncing back from the effects of change</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Social support</h3>
              <ul>
                <li>Building good relationships</li>
                <li>
                  Able to ask for support to overcome difficult situations
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Confidence</h3>
              <ul>
                <li>Feeling competent</li>
                <li>Coping effectively</li>
                <li>Strong self-esteem</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
