import React, { Component } from "react";
import CheckVisibility from "./CheckVisibility";

export default class extends Component {
  render = () => {
    let length = 1;
    if (this.props.children.props) {
      length = this.props.children.props.children.length;
    }
    return (
      <div
        className={`oblong ${
          this.props.className ? this.props.className : ""
        } -count-${length}`}
      >
        <CheckVisibility>{this.props.children}</CheckVisibility>
      </div>
    );
  };
}
