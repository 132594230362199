import React from "react";

export const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  return (
    <div className="modal">
      <div className="_inner">
        <div>{children}</div>
        <br />
        <button onClick={onClose} className="button -modal">
          Okay
        </button>
      </div>
    </div>
  );
};
