import React, { Component } from "react";
import { Pie } from "../../../components/Pie";
import icons from "../../../components/Icons";

const info = {
  title: "Overview",
  description: `By 2030 it’s estimated that there will be almost 1.2 million people of working age with cancer.<sup>1</sup>`,
};

export default class extends Component {
  static info = info;

  render = () => {
    return (
      <>
        <p>
          Many people with cancer can and often do choose to work through their
          illness and treatment — and for those who do, work can play an
          important role in their recovery by:
        </p>
        <ul>
          <li>Creating a sense of normality</li>
          <li>Improving psychological wellbeing</li>
          <li>Creating a structure and focus beyond cancer</li>
        </ul>
        {/* FYI: If we add an oblong back in, use the updated <Oblong /> component */}
        {/* Client: The copy is out of order. For example, ‘Triple whammy’ should appear later in the timeline and should not be a standalone visual. (See original copy doc).  */}
        {/* <div className="oblong -sum">
          <h3>The ‘triple whammy’ effect</h3>
          <ul className="facts">
            <li className="sum">
              <div>
                <span className="sum-icon">{icons.one}</span>
                <span>Diagnosis</span>
              </div>
            </li>
            <li className="sum -plus">
              <div>
                <span className="sum-icon">{icons.two}</span>
                <span>Negative work experience</span>
              </div>
            </li>
            <li className="sum -plus">
              <div>
                <span className="sum-icon">{icons.three}</span>
                <span>Collapse of self-esteem</span>
              </div>
            </li>
            <li className="sum -equals">
              <div>
                <span className="sum-icon">{icons.spiral}</span>
                <span>Downward spiral</span>
              </div>
            </li>
          </ul>
        </div> */}
        {/* <blockquote className="shift-up">
          <Pie value={85} />
          85% of people who were employed when diagnosed said it was important
          to continue work.<sup>1</sup>
        </blockquote>
        <h3>
          For those who choose to, work can play a vital role in recovery as it:
        </h3>
        <ul className="columns--2">
          <li>Creates a sense of normality</li>
          <li>Creates a structure and focus beyond cancer</li>
          <li>Can be crucial to psychological wellbeing</li>
        </ul>
      */}
        <p>
          But employers and employees may encounter barriers preventing people
          from returning to work, often because the appropriate support isn’t
          available.
        </p>
        <p>
          There may be a lack of regular and meaningful communication from both
          sides that needs to be overcome.
        </p>
        <h3>The ‘triple whammy’ effect</h3>
        <div className="triple-whammy trigger-me">
          <span>Diagnosis</span>
          <span className="_operator">+</span>
          <span>Negative work experience</span>
          <span className="_operator">+</span>
          <span>Collapse of self-esteem</span>
          <span className="_operator">=</span>
          <span>Downward spiral</span>
        </div>
        <h4>Employers also lose out</h4>
        <p>
          If companies do not provide adequate support to people with cancer:
        </p>
        <ul>
          <li>Talent is lost</li>
          <li>Staff morale is impacted</li>
          <li>The bottom line is affected</li>
        </ul>
        <h4>Line managers are vitally important</h4>
        <ul>
          <li>They have the biggest impact on someone working with cancer</li>
          <li>They need guidance or training to provide the support needed</li>
        </ul>
        <h4>Return to work policies</h4>
        <ul>
          <li>
            Should not pressure people to return to work before they are ready
          </li>
          <li>Should not delay recovery</li>
          <li>Should recognise that what works for one may not for another</li>
        </ul>
      </>
    );
  };
}
