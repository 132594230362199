import React, { Component } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import { slug, human } from "../lib/utilities";
export default class extends Component {
  render = () => {
    const {
        site,
        module,
        index,
        children,
        showAffiliateLogoAboveStuff,
        showCpdAccreditation,
      } = this.props,
      header = site.name,
      subheader = module ? module.component.info.title : null,
      description = module
        ? module.component.info.description
        : site.description,
      showClickOnASubjectMessage = module
        ? module.component.info.showClickOnASubjectMessage
        : false,
      showClickOnATroubleSpotMessage = module
        ? module.component.info.showClickOnATroubleSpotMessage
        : false,
      showClickOnAStepMessage = module
        ? module.component.info.showClickOnAStepMessage
        : false,
      affiliateLogos = site.affiliateLogos ? site.affiliateLogos : [],
      affs = affiliateLogos.length ? (
        <div className="affiliate-logos">
          {affiliateLogos.map((x, key) => (
            <div key={key}>
              <img className="affiliate-logo" src={x} alt="" />
            </div>
          ))}
        </div>
      ) : null,
      showAffiliatesInTheMasthead = site.showAffiliatesInTheMasthead;
    // console.log("MODULE", this.props, site, module);
    return (
      <>
        <div className="masthead">
          <div className="wrapper">
            <a
              href="https://www.unum.co.uk/workplace-wellbeing#interactive-modules"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="masthead-logo"
                src="images/logo-masthead.png"
                alt="Unum"
              />
            </a>
            {affs && showAffiliatesInTheMasthead && affs}
          </div>
        </div>
        <header className={`banner`}>
          <div className="wrapper">
            {/* <img src=".images/banner.png" alt="Banner" /> */}
            {/* Using an element background instead, so we can change it based on a parent class, see _banner.scss: */}
            <span className="img" />
            <div>
              {/* If module page, show breadcrumbs */}
              {module && (
                <ul className="breadcrumbs">
                  <li>
                    <Link to={`/${slug(site.name)}`}>
                      <strong>{human(site.name)}</strong>
                    </Link>
                  </li>
                  <li>
                    Module {index + 1}: {subheader}
                  </li>
                </ul>
              )}
              <div className="inner animate-all-children-slide">
                <h1>{header}</h1>
                {subheader && (
                  <h2 data-counter={index + 1}>
                    <span>{subheader}</span>
                  </h2>
                )}
                {affs &&
                  showAffiliateLogoAboveStuff &&
                  !showAffiliatesInTheMasthead &&
                  affs}
                {description &&
                  description
                    .split("\n")
                    .map((item, key) => (
                      <p key={key} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                {affs &&
                  !showAffiliateLogoAboveStuff &&
                  !showAffiliatesInTheMasthead &&
                  affs}
                {showCpdAccreditation && (
                  // Moved to Useful links and sources pages
                  // <CpdAccreditation {...{ cpdAccreditationUrl }} />
                  // Update [#3509] Simple sentence (and a separate CPD page)
                  <p>
                    As this e-module is CPD certified, please ensure you
                    complete each section to obtain your certificate.
                  </p>
                )}
                {showClickOnASubjectMessage && (
                  <p>Click on a subject for more information.</p>
                )}
                {showClickOnATroubleSpotMessage && (
                  <p>Click on a trouble spot for more information.</p>
                )}
                {showClickOnAStepMessage && (
                  <p>Click on a step for more information.</p>
                )}
                {children && children}
              </div>
            </div>
          </div>
        </header>
      </>
    );
  };
}
