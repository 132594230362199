import React, { Component } from "react";
import Video from "../../../components/Video";

const info = {
  title: "Bereavement in the workplace",
  description: `It can be incredibly difficult for employees if a colleague passes away — and bereavement is a natural reaction. We often form close bonds and friendships in the workplace, but it can be difficult to know where to turn or who to ask for help when we lose a co-worker we’re close to.`,
};

export default class extends Component {
  static info = info;

  render = () => {
    return (
      <>
        <p>
          Providing bereavement support to employees who worked closely with a
          colleague who passes away will help them, their family and other
          people within your organisation.
        </p>
        <p>Key things to consider in such a situation include:</p>
        <ul>
          <li>Understanding the effects of grief </li>
          <li>Staying in close, regular contact with those impacted </li>
          <li>Understanding that time off work may be required </li>
          <li>Being aware that grief takes time </li>
          <li>
            Knowing that everyone processes grief at different speeds and in
            different ways
          </li>
        </ul>
        <Video file="Emodule_BEREAVEMENT_003_" />
      </>
    );
  };
}
