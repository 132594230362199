import { info, CpdCertificate } from "../../cpd-certificate";
const _module = CpdCertificate({
  cpdCertificateUrl: "https://www.brainshark.com/unumuk/MHtest",
  content: "",
});
_module.info = {
  ...info,
  description: `You have now completed our <em>Mental health</em> e-module. We hope you found it useful and now feel more informed about the huge impact of mental ill health among for not only the employee themselves but also you and your business.`,
};
export default _module;
