import { dir } from "./lib/utilities";
import site1 from "./data/cancerInTheWorkplace/";
import site2 from "./data/stressAwareness/";
import site3 from "./data/sicknessAbsence/";
import site4 from "./data/musculoskeletalDisorders/";
import site5 from "./data/bereavement/";
import site6 from "./data/mentalHealth/";

let _sites = [site1, site2, site3, site4, site5, site6];

// Iterate over sites array, setting dir value and importing their respective module components
_sites = _sites.map((site) => {
  site.dir = dir(site.name);
  return site;
});
_sites = _sites.map((site) => {
  site.modules = site.modules.map((name) => {
    return {
      name,
      component: require(`./data/${site.dir}/Modules/${name}`).default,
    };
  });
  return site;
});
// Sort alphabetically by name, for listing page
_sites = _sites.sort((a, b) => {
  return a.name > b.name ? 1 : -1;
});

export const sites = _sites;
