import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-managing-new-5.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting a bereaved colleague",
  description: `Understanding the challenges that a bereaved employee may face and considering the effect and impact it might have on work is critical.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Your role as line manager",
      "When the death happens",
      "Before someone returns to work",
      "When someone returns to work",
      "Signs someone may be struggling",
      "What not to do",
    ],
    lineLengthMax: 15, // because 'Signs someone may be struggling'
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      {/* <h3 className="graphic-header -b">Your role as a manager</h3> */}
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>When the death happens</h3>
              <ul>
                <li>Be caring and compassionate</li>
                <li>
                  Ensure the employee knows what leave they’re entitled to
                </li>
                <li>Ask how they would like to stay in contact with work</li>
                <li>Ask how much they want colleagues to know</li>
                <li>Ask if they want colleagues to contact them</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Before someone returns to work</h3>
              <ul>
                <li>
                  Discuss an appropriate time to return to the workplace that
                  works for the individual
                </li>
                <li>
                  Consider possible adjustments — e.g. a phased return to work
                  or a temporary change of duties, workplace location, travel
                  requirements etc.
                </li>
                <li>
                  Be aware of changes in personal circumstances, such as
                  childcare or eldercare responsibilities
                </li>
              </ul>{" "}
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>When someone returns to work</h3>
              <ul>
                <li>Keep in regular communication</li>
                <li>
                  Provide information for any services, such as an Employee
                  Assistance Programme if available
                </li>
                <li>
                  Take bereavement into account if efficiency, effectiveness or
                  performance suffers
                </li>
                <li>Consider the impact on other members of the team</li>
                <li>
                  Remember, the full impact of a bereavement may not be felt for
                  some time after the death
                </li>
                <li>
                  People may think they are coping better than they really are,
                  so look for changes in behaviour
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Signs someone may be struggling</h3>
              <ul>
                <li>Fatigue (emotional distress can be exhausting)</li>
                <li>Lack of concentration</li>
                <li>Wanting to be at work while at home or vice versa</li>
                <li>
                  Restlessness, physical illnesses, higher susceptibility to
                  minor illnesses
                </li>
                <li>Generally feeling under the weather</li>
                <li>
                  Not wanting to go home/finish for the day and/or working long
                  hours
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>What not to do</h3>
              <ul>
                <li>Don’t ignore the death</li>
                <li>
                  Don’t assume you know how they’re feeling – every bereavement
                  is unique
                </li>
                <li>Don’t say anything to minimise or undermine the loss</li>
                <li>
                  Don’t make the assumption that just because they are back at
                  work, they are ‘over it’ and ‘back to normal’
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
