import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-psychology.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Psychological side effects",
  description: `Cancer is understandably a traumatic experience. A person diagnosed can experience a range of emotions. While these are perfectly normal, they can be difficult to handle.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Cognitive", "Emotional", "Fatigue"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Cognitive</h3>
              <p>
                Cognition is the ability to process information, reason,
                remember and relate.
              </p>
              <p>
                Mild Cognitive Impairment (MCI) is sometimes referred to as
                'chemo brain' although there is no clear link to chemotherapy.
              </p>
              <h4>Some cognitive impairments include:</h4>
              <ul>
                <li>Short-term memory loss</li>
                <li>Mental fogginess</li>
                <li>Word finding</li>
                <li>Following conversation or verbal recall</li>
                <li>Visual memory, image or list recall</li>
                <li>Multi-tasking</li>
                <li>Learning new skills</li>
                <li>Concentrating or focusing.</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Emotional</h3>
              <p>
                Cancer is a serious and stressful life event for the individual
                but also for friends, relatives and colleagues. It can often
                take away a sense of security and leave uncertain and uneasy
                thoughts about what's ahead.
              </p>
              <h4>Common fears and thoughts include:</h4>
              <ul>
                <li>Loss of life</li>
                <li>Loss of freedom and independence</li>
                <li>Loss of friends who can't face the illness</li>
                <li>Loss of work contacts</li>
                <li>Loss of job</li>
              </ul>
              <p>
                Post-traumatic stress reactions can occur even after the
                'all-clear'. It is harder to explain or understand these
                feelings as people expect to feel more positive.
              </p>
              <p>
                Shock, numbness, anxiety, anger, sadness, guilt, sorrow,
                withdrawal and loss of confidence are all common, normal
                reactions and feelings.
              </p>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Fatigue</h3>
              <p>
                Fatigue means feeling very tired or exhausted all or most of the
                time. I can impact all areas of an individual's life and affects
                everyone differently.
              </p>
              <p>
                It is not relieved by rest and can have psychological and
                emotional effects leading to anxiety, depression and stress.
              </p>
              <p>
                Fatigue is one of the largest issues associated with cancer and
                treatment and can be one of the hardest things to cope with when
                trying to maintain a normal routine.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
