import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-hands-3.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting an absent employee",
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Communication",
      "Return to work assistance",
      "When communication is difficult",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Communication</h3>
              <ul>
                <li>
                  Regular communication can help to reduce anxiety about
                  returning to work
                </li>
                <li>Regular, timely contact keeps the employee engaged</li>
                <li>
                  It should be personal, supportive and non-intrusive contact
                </li>
                <li>
                  Consider home visits or invite them in to work for an informal
                  chat
                </li>
                <li>
                  Provide information on pay and any staff benefits like Income
                  Protection
                </li>
                <li>
                  Get their consent early in case you need to write to their GP
                  or health professional
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Return to work assistance</h3>
              <ul>
                <li>Return to work interviews</li>
                <li>Identifying support and solutions</li>
                <li>Plan ahead</li>
                <li>Phased return to work planning</li>
                <li>Consider workplace adjustments</li>
                <li>Review, review, review!</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>When communication is difficult</h3>
              <p>If you can’t get in touch with your employee:</p>
              <ul>
                <li>Write to them explaining you’re here to help</li>
                <li>Tell them why you want to get in touch</li>
                <li>Say when you’ll next call them</li>
              </ul>
              <p>If your employee doesn’t want to talk to you:</p>
              <ul>
                <li>Try to establish why</li>
                <li>
                  Agree alternative ways of keeping in touch — email with
                  someone else present
                </li>
                <li>
                  Line managers are key, but HR may be a good alternative
                  contact
                </li>
                <li>
                  Remember, if they don’t keep in touch with anyone, they’re
                  less likely to return at all
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
