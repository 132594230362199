export default {
  name: "Bereavement",
  modules: [
    "whatIsBereavement",
    "workingThroughBereavement",
    "bereavementInTheWorkplace",
    "supportingABereavedColleague",
    "supportIfAColleagueDies",
    "cpd-certificate",
    "useful-links-and-sources",
  ],
  description:
    "We’ve created this workshop in partnership with St Catherine's Hospice. It walks you through modules looking at bereavement, including downloadable tools and resources.",
  affiliateLogos: ["images/logo-st-catherines.png"],
  showAffiliatesInTheMasthead: true,
  cpdCertificateUrl: "#",
};
