export default {
  name: "Sickness absence",
  modules: [
    "whyManageAbsence",
    "preventingSicknessAbsence",
    "supportingAnEmployeeDuringAbsence",
    "supportingAnEmployeesReturnToWork",
    "whatElseDoYouNeedToKnow",
    "cpd-certificate",
    "useful-links-and-sources",
  ],
  description:
    "This workshop walks you through the key areas of absence management and why it's so important. It also includes downloadable tools and resources.",
};
