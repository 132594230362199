import React, { Component } from "react";
import Video from "../../../components/Video";

const info = {
  title: "Why manage absence?",
  description: `Managing sickness absence can help prevent short-term issues becoming long-term problems. Watch our short video below for more information on the importance of managing sickness absence.`,
};

export default class extends Component {
  static info = info;

  render = () => {
    return (
      <>
        <Video file="Emodule_SICKNESS_005_" />
      </>
    );
  };
}
