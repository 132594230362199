import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-feet-6.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Managing stress-related problems",
  description: `Helping an employee who may be stressed can include different approaches. Here are some practical steps you can take.`,
  showClickOnAStepMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "What's the evidence?",
      "Understanding the issues",
      "Identify solutions",
      "Agree an action plan",
      "Implement solutions",
      "Review",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>What's the evidence?</h3>
              <p>Use what you know to help start the conversation.</p>
              <ul>
                <li>Observations: You’ve seen the changes</li>
                <li>Listening: You’ve heard problems mentioned</li>
                <li>
                  Records: Do they show reduced attendance and/or increased
                  lateness?
                </li>
                <li>
                  Knowledge: You’re aware of possible work-related stressors
                </li>
              </ul>
              {/* <p>
                <a
                  className="download-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://online.positiveimagesuk.com/unum/download/UP3305.pdf"
                >
                  Download: Preparing and starting the discussion
                </a>
              </p> */}
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Understanding the issues</h3>
              <ul>
                <li>
                  Set the scene: It’s about support and help in a relaxed and
                  private setting
                </li>
                <li>
                  Prepare and start the discussion: Choose an appropriate venue
                  away from others
                </li>
                <li>
                  Listen to what the person has to say and gather information
                </li>
                <li>Understand: Work together to recognise the issues</li>
                <li>
                  Take your time — don’t feel pressured to come up with
                  immediate answers
                </li>
                <li>Involve others if you feel it’s necessary</li>
              </ul>
              {/* <p>
                <a
                  className="download-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://online.positiveimagesuk.com/unum/download/UP3306.pdf"
                >
                  Download: Exploring potential wellbeing factors
                </a>
              </p> */}
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Identify solutions</h3>
              <h4>When you can make changes to the job</h4>
              <ul>
                <li>
                  Responsibilities: Change their responsibilities, tasks or
                  hours
                </li>
                <li>Environment: Look to modify or offer some home working</li>
                <li>
                  Reduce or stop contact with people or customers for a limited
                  period
                </li>
              </ul>
              <h4>When you can’t make changes to the job</h4>
              <ul>
                <li>
                  Prioritise: Help identify which tasks are more important
                </li>
                <li>
                  Training: Offer work-related training or support, or peer
                  support
                </li>
                <li>Support: Weekly catch ups to offer social support</li>
                <li>
                  Problem solving: Suggest taking leave to manage a difficult
                  period
                </li>
              </ul>
              <h4>When work isn’t the problem</h4>
              <ul>
                <li>Time away: Offer compassionate leave</li>
                <li>
                  Support: Offer counselling or support from an employee
                  assistance programme (EAP)
                </li>
              </ul>
              <h4>Communicate</h4>
              <p>Ask what they would like to happen</p>
              {/* <p>
                <a
                  className="download-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://online.positiveimagesuk.com/unum/download/UP3307.pdf"
                >
                  Download: Possible solutions and discussing solutions
                </a>
              </p> */}
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Agree on action plan</h3>
              <ul>
                <li>
                  Discuss answers: Decide together which will be the most
                  effective
                </li>
                <li>
                  Involvement: Ensure employee is involved in any decision
                </li>
                <li>Specific and time-limited: Set clear review dates</li>
                <li>
                  Implications: Decide what these are and what you will say to
                  colleagues
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>Implement solutions</h3>
              <ul>
                <li>
                  Act immediately: Short-term anxieties can become long-term
                  problems
                </li>
                <li>
                  Prioritise: Prioritise and tackle the main causes of stress
                </li>
                <li>
                  Seek support where necessary: HR, Occupational Health, etc.
                </li>
                <li>
                  Keep records: Meetings held, action plan and actions taken
                </li>
              </ul>
            </>
          )}

          {this.state.svgActive === "5" && (
            <>
              <h3>Review</h3>
              <p>
                At agreed points, get together and check on progress. Consider
                discussing:
              </p>
              <ul>
                <li>What’s working and what isn’t</li>
                <li>Further solutions if necessary</li>
                <li>
                  Agree a further action plan and set a date for another review
                </li>
              </ul>
              {/* <p>
                <a
                  className="download-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://online.positiveimagesuk.com/unum/download/UP3308.pdf"
                >
                  Download: Summary of steps to manage stress
                </a>
              </p> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}
