import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-mental-health-continuum.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "The mental health continuum",
  description: `A person’s mental health is constantly changing — even on a daily basis — and may not follow a linear process of ‘treatment » improvement » back to work’.
  Each person’s experience of mental ill-health can be very fluid, moving anywhere between optimal and minimal wellbeing, and minimum to maximum mental health.`,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Cognitive", "Physical", "Behavioural", "Emotional"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <continuum-quiz></continuum-quiz>
    </>
  );
}
