import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { cssAnimationTriggersAfterSlideIn } from "../lib/cssAnimationTriggersAfterSlideIn";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { slug } from "../lib/utilities";
function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}
class Module extends Component {
  state = {};
  setModuleFromProps = async (props) => {
    let index = parseInt(props.params.index) - 1;
    this.isPenultimateModule = index === props.site.modules.length - 2;
    this.isFinalModule = index === props.site.modules.length - 1;
    this.setState({
      index,
      module: props.site.modules[index],
      site: props.site, // e.g. stressAwareness/index.jsx
      moduleCompleted: false,
    });
    this.setState(
      {
        animationTrigger: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ animationTrigger: false });
        }, 200);
      }
    );
  };
  componentDidMount() {
    this.setModuleFromProps(this.props);
    cssAnimationTriggersAfterSlideIn();
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setModuleFromProps(props);
  }
  render() {
    if (!this.state.module) return null;
    return (
      <div
        className={`page page--module ${
          this.state && this.state.animationTrigger
            ? "animation-trigger"
            : "animation-triggered"
        }`}
      >
        <Header
          {...this.state}
          {...this.props}
          showAffiliateLogoAboveStuff={true}
        />
        <main
          className={`${
            this.state.animationTrigger
              ? "animation-trigger"
              : "animation-triggered"
          } ready-for-graphic-animation`}
        >
          <div className="wrapper">
            <div
              className={`${
                this.state.animationTrigger
                  ? ""
                  : "animate-all-children-slide-after-header"
              } inner`}
            >
              <this.state.module.component />
              {this.isPenultimateModule &&
                this.state.site.completionStatement && <></>}
              {/* Leave empty div to pad final module */}

              {!this.isFinalModule && (
                <Link
                  to={`/${slug(this.props.site.name)}/modules/${
                    this.state.index + 2
                  }`}
                  className="cta-continue"
                >
                  I have read and understood this section, continue onward
                </Link>
              )}
            </div>
          </div>
        </main>
        <Footer
          {...this.props}
          module={this.state.module}
          moduleCompleted={this.state.moduleCompleted}
          enableTickBoxModal={true}
        />
      </div>
    );
    // if (!this.state.module) {
    //   return <p>Loading..</p>;
    // }
    // return (
    //   <main
    //     className={this.state.animationTrigger ? "animation-trigger" : null}
    //   >
    //     <h1>To do</h1>
    //     {/* Display render function of given module, passing along this state as props */}
    //     <this.state.module {...this.state} />
    //   </main>
    // );
  }
}
export default withParams(Module);
