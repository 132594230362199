import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-person.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "What are common MSDs?",
  description: `MSDs can be both work-related and pre-existing, often causing pain and impairing normal everyday activities.`,
  showClickOnATroubleSpotMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    hotspots: [
      { x: 40, y: 180 }, // Fibromyalgia
      { x: 150, y: 160 }, // Arthritis
      { x: 200, y: 280 }, // Bursitis
      { x: 0, y: 360 }, // RSI
      { x: 30, y: 500 }, // Tendonitis
      { x: 150, y: 580 }, // Sprains
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic -narrow -valign` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Fibromyalgia</h3>
              <ul>
                <li>Widespread pain affecting the whole body</li>
                <li>Typically chronic</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Arthritis</h3>
              <ul>
                <li>Umbrella term for more than 100 different types</li>
                <li>Causes pain, stiffness and limited movement</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Bursitis</h3>
              <ul>
                <li>
                  Painful swelling in the joints, typically shoulder, elbow and
                  knee
                </li>
                <li>Caused by repetitive movement or sustained pressure</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Repetitive Strain Injury (RSI)</h3>
              <ul>
                <li>Typically caused by repeated and sustained tasks</li>
                <li>
                  Pain and numbness in the hand which may extend to the arm,
                  shoulder or neck
                </li>
                <li>
                  Common RSI is Carpal Tunnel Syndrome (affecting the wrist)
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>Tendonitis</h3>
              <ul>
                <li>Caused by overuse and injury</li>
                <li>
                  Inflamed and painful tendon often seen in shoulder, elbow,
                  wrist and knee
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "5" && (
            <>
              <h3>Sprains and strains</h3>
              <ul>
                <li>Typically acute</li>
                <li>
                  Causes initial pain, swelling and limited weight bearing
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
