import React, { Component } from "react";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Resource from "../../../components/resource";
import { OtherModulesLink } from "../../../components/OtherModulesLink";
const info = {
  title: "Useful links and sources",
  description: ``,
};
export default class extends Component {
  static info = info;
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h2 className="shift-up">What is stress?</h2>
      <div className="resources">
        {[
          {
            title: "1. Health and wellbeing at work 2021",
            url: "https://www.cipd.co.uk/Images/health-wellbeing-work-report-2021_tcm18-93541.pdf",
          },
          {
            title: "2. How to turn everyday stress into 'optimal stress'",
            url: "https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/how-to-turn-everyday-stress-into-optimal-stress",
          },
          {
            title: "What is stress?",
            url: "http://online.positiveimagesuk.com/unum/download/UP3303.pdf",
          },
          {
            title: "Take the test Exploring potential stressors at work",
            url: "http://online.positiveimagesuk.com/unum/download/UP3306.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Why manage stress?</h2>
      <div className="resources">
        {[
          {
            title: "1. CIPD Health and Wellbeing at Work Report 2021, p.15",
            url: "https://www.cipd.co.uk/Images/health-wellbeing-work-report-2021_tcm18-93541.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Recognising stress</h2>
      <div className="resources">
        {[
          {
            title: "Full list of behavioural changes",
            url: "http://online.positiveimagesuk.com/unum/download/UP3304.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>COVID-19 and stress</h2>
      <div className="resources">
        {[
          {
            title: "Health and wellbeing at work 2021",
            url: "https://www.cipd.co.uk/Images/health-wellbeing-work-report-2021_tcm18-93541.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Your role as a line manager</h2>
      <div className="resources">
        {[
          {
            title: "Stress Management: Are you an effective manager?",
            url: "https://www.hse.gov.uk/stress/mcit.pdf",
          },
          {
            title: "Discussing workplace stress",
            url: "http://online.positiveimagesuk.com/unum/download/UP3305.pdf",
          },
          {
            title: "Potential solutions",
            url: "http://online.positiveimagesuk.com/unum/download/UP3307.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Keeping you and your team healthy</h2>
      <div className="resources">
        {[
          {
            title: "How to manage stress",
            url: "http://online.positiveimagesuk.com/unum/download/UP3308.pdf",
          },
          {
            title: "What is resilience?",
            url: "https://www.robertsoncooper.com/blog/what-is-resilience/",
          },
          {
            title:
              "The Robertson Cooper stress questionnaire (follow the links to complete)",
            url: "https://www.robertsoncooper.com/iresilience/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <OtherModulesLink />
    </>
  );
}
