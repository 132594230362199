import React, { Component } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import { slug } from "../lib/utilities";
import { Modal } from "./Modal";

export default class extends Component {
  state = {
    showTickBoxModal: false,
  };
  render = () => {
    const { modules } = this.props.site,
      { module } = this.props,
      moduleUrl = `/${slug(this.props.site.name)}/modules`;

    // Garner the current index, or falsey otherwise
    if (modules && module) {
      this.index = modules.map((m) => m.name).indexOf(module.name);
    } else {
      this.index = -1;
    }
    // Previous and next indexes if suitable, else falsey which prevents rendering link
    let indexPrev = this.index > 0 ? this.index - 1 : null;
    let indexNext = this.index < modules.length - 1 ? this.index + 1 : null;

    // Client wants full text rather than 'Prev' and 'Next'
    let modulePrev =
      indexPrev !== null ? modules[indexPrev].component.info.title : null;
    let moduleNext =
      indexNext !== null ? modules[indexNext].component.info.title : null;

    return (
      <>
        <footer role="contentinfo">
          <div className="wrapper">
            <div>
              {indexPrev !== null && (
                <Link
                  to={`${moduleUrl}/${indexPrev + 1}`}
                  className="nav-item--prev"
                >
                  <span>
                    <i>Module {indexPrev + 1}: </i>
                    {modulePrev}
                  </span>
                </Link>
              )}
            </div>
            <ul className="pagination">
              {modules.map((m, i) => {
                const enabled =
                  i == 0 ||
                  (i == this.index + 1 && this.props.moduleCompleted) ||
                  i < this.index;
                return (
                  <li key={i}>
                    <Link
                      to={`${moduleUrl}/${i + 1}`}
                      className={
                        module && m.name === module.name ? "active" : " "
                      }
                      onClick={(e) => {
                        if (!enabled) {
                          e.preventDefault();
                          this.setState({ showTickBoxModal: true });
                        }
                      }}
                      disabled={!enabled}
                      style={{
                        // pointerEvents: enabled ? "auto" : "none",
                        opacity: enabled || i == this.index ? "1" : ".5",
                      }}
                    >
                      {i + 1}
                      <span>{m.component.info.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div>
              {indexNext !== null &&
                (this.props.moduleCompleted || indexNext == 0) && (
                  <Link
                    to={`${moduleUrl}/${indexNext + 1}`}
                    className="nav-item--next"
                  >
                    <span>
                      <i>Module {indexNext + 1}: </i>
                      {moduleNext}
                    </span>
                  </Link>
                )}
            </div>
          </div>
        </footer>
        <Modal
          show={this.props.enableTickBoxModal && this.state.showTickBoxModal}
          onClose={() => this.setState({ showTickBoxModal: false })}
        >
          Please confirm that you have read and understood this section, by
          clicking continue at the end of the page.
        </Modal>
      </>
    );
  };
}
