import React, { Component } from "react";
import { startGreensockAnimations } from "../../../lib/greensockAnimations";
import Resource from "../../../components/resource";
import { OtherModulesLink } from "../../../components/OtherModulesLink";
const info = {
  title: "Useful links and sources",
  description: ``,
};
export default class extends Component {
  static info = info;
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h2 className="shift-up">What are MSDs?</h2>
      <div className="resources">
        {[
          {
            title: "1. Work-related musculoskeletal disorders statistics",
            url: "https://www.hse.gov.uk/statistics/causdis/msd.pdf",
          },
          {
            title: "2. Working days lost in Great Britain",
            url: "https://www.hse.gov.uk/statistics/dayslost.htm",
          },
          {
            title: "3. Chronic pain and mental health are connected",
            url: "https://www.hingehealth.com/resources/articles/chronic-pain-and-mental-health-are-connected-the-solution-is-too",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Back Pain</h2>
      <div className="resources">
        {[
          {
            title: "1. NICE: Back pain",
            url: "https://cks.nice.org.uk/topics/back-pain-low-without-radiculopathy/background-information/prevalence/",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <h2>Related links</h2>
      <div className="resources">
        {[
          {
            title: "Ergonomics information",
            url: "https://www.unum.co.uk/docs/UP3343.pdf",
          },
          {
            title: "Desk exercises",
            url: "https://www.unum.co.uk/docs/UP3341.pdf",
          },
          {
            title: "Workstation assessment plan",
            url: "https://www.unum.co.uk/docs/UP3342.pdf",
          },
          {
            title: "Psychological impact of MSDs",
            url: "https://www.unum.co.uk/docs/UP3344.pdf",
          },
        ].map((r, i) => (
          <Resource {...r} key={`r-${i}`} />
        ))}
      </div>
      <OtherModulesLink />
    </>
  );
}
