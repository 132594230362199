import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-persons-unisex.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "What is bereavement?",
  description: `Bereavement is the experience of losing someone important to us. It’s characterised by our sense of grief and loss when someone we know dies.

  Grief is something that affects people in different ways. The feelings can occur at any time. There is no limit or timeframe on grief, and this is normal.`,
  showClickOnATroubleSpotMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    hotspots: [
      { x: 90, y: 5, headerChange: "Effect of grief" }, // disbelief
      { x: 165, y: 180, headerChange: "Effect of grief" }, // numbness
      { x: 67, y: 166, headerChange: "Effect of grief" }, // anger
      { x: 157, y: 42, headerChange: "Effect of grief" }, // uncontrollable crying
      { x: 235, y: 286, headerChange: "Effect of grief" }, // uncharacteristic behaviour
      { x: 100, y: 250, headerChange: "Effect of grief" }, // guilt
      { x: 94, y: 350, headerChange: "Effect of grief" }, // emptiness
      { x: 70, y: 504, headerChange: "Effect of grief" }, // autopilot
      { x: 170, y: 391, headerChange: "Effect of grief" }, // relief

      { x: 740, y: 420, headerChange: "Effect of bereavement" }, // relief
      { x: 661, y: 331, headerChange: "Effect of bereavement" }, // physically
      { x: 732, y: 260, headerChange: "Effect of bereavement" }, // emotionally
      { x: 658, y: 191, headerChange: "Effect of bereavement" }, // spiritually
      { x: 718, y: 16, headerChange: "Effect of bereavement" }, // psychologically
    ],
    labels: ["Grief", "Bereavement"],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <h3
        className="graphic-header -a"
        id="headerChange"
        style={{ marginLeft: "1em" }}
      >
        Effects of bereavement and grief
      </h3>
      <div
        className={
          `graphic -valign -bubble` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <div>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </div>
        )}
        <div className="graphic_text -bubble">
          {this.state.svgActive === "0" && (
            <div>
              <p>Disbelief</p>
            </div>
          )}
          {this.state.svgActive === "1" && (
            <div>
              <p>Numbness</p>
            </div>
          )}
          {this.state.svgActive === "2" && (
            <div>
              <p>Anger</p>
            </div>
          )}
          {this.state.svgActive === "3" && (
            <div>
              <p>Uncontrollable crying</p>
            </div>
          )}
          {this.state.svgActive === "4" && (
            <div>
              <p>Uncharacteristic behaviour</p>
            </div>
          )}
          {this.state.svgActive === "5" && (
            <div>
              <p>Guilt</p>
            </div>
          )}
          {this.state.svgActive === "6" && (
            <div>
              <p>Emptiness</p>
            </div>
          )}
          {this.state.svgActive === "7" && (
            <div>
              <p>Being on autopilot</p>
            </div>
          )}
          {this.state.svgActive === "8" && (
            <div>
              <p>Relief</p>
            </div>
          )}
          {this.state.svgActive === "9" && (
            <div>
              <p>Physical: Stress-related illnesses</p>
            </div>
          )}
          {this.state.svgActive === "10" && (
            <div>
              <p>Emotional: Feeling strong emotions or out of control</p>
            </div>
          )}
          {this.state.svgActive === "11" && (
            <div>
              <p>Spiritual: Crisis of faith/meaning of life challenged</p>
            </div>
          )}
          {this.state.svgActive === "12" && (
            <div>
              <p>Social: Change in role/status</p>
            </div>
          )}
          {this.state.svgActive === "13" && (
            <div>
              <p>Psychological: Threat to self-identity</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
