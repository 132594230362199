import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-hands-5.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Supporting an employee during absence",
  description: `There will always be times when people need time off due to illness or injury, but it’s not just a question of waiting patiently for them to return.
  There are things you can do to help.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Communication",
      "Sickness absence policy",
      "Return to Work Assistance",
      "Getting better informed",
      "When communication is difficult",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Communication</h3>
              <ul>
                <li>
                  Regular communication helps reduce anxiety about returning to
                  work
                </li>
                <li>Regular, timely contact keeps employee engaged</li>
                <li>
                  It should be personal, supportive and non-intrusive contact
                </li>
                <li>
                  Consider home visits or invite them in to work for an informal
                  chat
                </li>
                <li>
                  Provide information on pay and any staff benefits like Income
                  Protection
                </li>
                <li>
                  Get their consent early in case you need to write to their GP
                  or health professional
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Sickness absence policy</h3>
              <p>
                Make sure your employee is aware of the company’s policy. We
                recommend the policy should:
              </p>

              <ul>
                <li>
                  Explain both the employee’s and employer’s responsibilities
                </li>
                <li>
                  Include information on pay and any staff benefits like Income
                  Protection, which are important to share upfront
                </li>
                <li>
                  Clarify who employees have to inform when off sick, plus how
                  and when they have to do it
                </li>
                <li>
                  Clarify action points — certificates, medical examinations
                  etc.
                </li>
                <li>
                  Outline return to work procedures which reassures consistent
                  processes and fair treatment
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Return-to-work assistance</h3>
              <ul>
                <li>Highlight available resources</li>
                <li>
                  Employee Assistance Programme (EAP) — discreet, external,
                  flexible expertise and counselling
                </li>
                <li>
                  Staff benefits — Income Protection typically offers access to
                  medical and vocational expertise
                </li>
                <li>
                  Occupational health — particularly useful for complex
                  conditions
                </li>
                <li>
                  Private Medical Insurance — can help bypass NHS waiting lists
                </li>
                <li>
                  Access to Work — can help fund employee’s commute, equipment
                  or support mental health concerns
                </li>
                <li>Offer return-to-work support when they are ready</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Getting better informed</h3>
              <ul>
                <li>Understand the nature of the illness or injury</li>
                <li>Consider if the illness is affecting their performance</li>
                <li>Understand likely recovery times</li>
                <li>
                  Understand possible reduced capabilities and ask them how they
                  are managing
                </li>
                <li>
                  Think about possible need for changes in the workplace,
                  workstations or support
                </li>
                <li>
                  Visit the NHS website, other specialist websites or read
                  medical guidelines
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "4" && (
            <>
              <h3>When communication is difficult</h3>
              <h4>If you can’t get in touch with your employee:</h4>
              <ul>
                <li>Write to them explaining you’re here to help</li>
                <li>Tell them why you want to get in touch</li>
                <li>Say when you’ll next call them</li>
              </ul>
              <h4>If your employee doesn’t want to talk to you:</h4>
              <ul>
                <li>Try to establish why</li>
                <li>
                  Agree alternative ways of keeping in touch — email with
                  someone else present
                </li>
                <li>
                  Line managers are key, but HR may be a good alternative
                  contact
                </li>
              </ul>
              <p>
                Remember, if they don’t keep in touch with anyone, they’re less
                likely to return at all.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
