import React, { Component } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import { slug } from "../lib/utilities";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default class extends Component {
  componentDidMount() {
    this.setState(
      {
        animationTrigger: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ animationTrigger: false });
        }, 200);
      }
    );
  }
  render() {
    const { site } = this.props,
      { modules } = site;
    return (
      <div
        className={`page page--site ${
          this.state && this.state.animationTrigger
            ? "animation-trigger"
            : "animation-triggered"
        }`}
      >
        <Header site={site} showCpdAccreditation={true}>
          <nav className="nav--content animate-all-children-zoom">
            {modules.map((m, i) => {
              const disabled = i > 0;
              return (
                <h2
                  data-counter={i + 1}
                  key={i}
                  className={disabled ? "disabled" : ""}
                >
                  <Link to={`/${slug(this.props.site.name)}/modules/${i + 1}`}>
                    {m.component.info.title}
                  </Link>
                </h2>
              );
            })}
          </nav>
        </Header>
        <main>
          <div className="wrapper">
            <div className="inner animate-all-children-slide" />
          </div>
          <div className="footer-site">
            Unum Limited is authorised by the Prudential Regulation Authority
            and regulated by the Financial Conduct Authority and the Prudential
            Regulation Authority. Unum Limited is on the FCA Financial Services
            Register, Firm Reference Number: 110408. Registered and Head Office:
            Milton Court, Dorking, Surrey, RH4 3LZ. Registered in England
            company number 983768.
          </div>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
