import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollIframeWrapper from "./ScrollIframeWrapper";
import { sites } from "./Data";
import { slug, human, dir } from "./lib/utilities";

import { applyPolyfills, defineCustomElements } from "continuum-quiz/loader"; // within the repo in dist (resolving with craco webpack override), rather than an npm package

// If at / show a list of links to the individual sites, otherwise load the app proper
let sitePath = window.location.pathname;

// If serving from a sub folder, strip it's dir from the path when referenced, so we can figure out which site we're currently on
sitePath =
  sitePath.substr(0, process.env.PUBLIC_URL.length) === process.env.PUBLIC_URL
    ? sitePath.substr(process.env.PUBLIC_URL.length)
    : sitePath;

let siteSlug = sitePath.split("/")[1]; // /cancer-in-the-workplace
let siteId = dir(siteSlug); // cancerInTheWorkplace
const site = sites.filter((s) => dir(s.name) === siteId)[0];
// console.log(":: ~ sites", sites);

const container = document.getElementById("root");
const root = createRoot(container);

if ((siteSlug && site) || siteId === "demo") {
  // Load the app with appropriate site props
  root.render(
    <ScrollIframeWrapper className={site && slug(site.name)}>
      {window.location.hostname === "localhost" && (
        <aside className="h4x0r-menu">
          <header>Localhost-only H4x0r Menu</header>
          <ul>
            {sites.map((s, i) => (
              <li key={i}>
                <a href={`/${slug(s.name)}`}>{human(s.name)}</a>
                <ul>
                  {s.modules.map((m, j) => (
                    <li key={j}>
                      <a href={`/${slug(s.name)}/modules/${j + 1}`}>
                        {m.component.info.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </aside>
      )}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App site={site} />
      </BrowserRouter>
    </ScrollIframeWrapper>
  );
} else {
  root.render(
    <main>
      <div className="wrapper">
        <div className="inner">
          <h1>Unum Microsites List</h1>
          <nav className="nav--index">
            {sites.map((s, i) => (
              <a key={i} href={`${process.env.PUBLIC_URL}/${slug(s.name)}`}>
                {human(s.name)}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </main>
  );
}

applyPolyfills().then(() => {
  defineCustomElements(window);
});
