import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-psych-8.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Psychological impact of MSDs",
  description: `When employers and employees think about MSDs, the focus is typically on the physical problems. However, MSDs can also have many psychological effects. Take time to look over the areas mentioned below to consider the psychological impacts MSDs could result in.`,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Irritability",
      "Loss of control",
      "Depression",
      "Avoidance",
      "Social Isolation",
      "Reduced concentration",
      "Anxiety",
      "Hopelessness",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
      </div>
    </>
  );
}
