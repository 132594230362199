import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-back-4.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Back pain",
  description: `Back pain is common. Up to 60% of UK adults of adults are expected to experience low back pain at some point in their lives.<sup>1</sup>
Not all conditions lead to pain. For example, even people without back pain may have bulging discs.`,
  showClickOnASubjectMessage: true,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: ["Pain", "Keep active", "Typical treatments", "Recovery"],
    lineLengthMax: 14, // because '[Not all conditions] lead to pain'
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>Pain</h3>
              <ul>
                <li>More back pain doesn’t mean more damage</li>
                <li>Pain can be different for every individual</li>
                <li>
                  Abnormal scan results may unnecessarily lead to further fear,
                  distress and avoidance of activity if misinterpreted
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Keep active</h3>
              <ul>
                <li>
                  Keeping active and pacing a return to all usual activities is
                  important in aiding recovery
                </li>
                <li>Prolonged bed rest is not helpful</li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Recovery</h3>
              <ul>
                <li>Even persistent back pain can get better</li>
                <li>Consider a multi-disciplinary approach to recovery</li>
                <li>
                  Consider both physical and psychological wellbeing, such as
                  tackling stress
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Typical treatments</h3>
              <ul>
                <li>Physiotherapy</li>
                <li>Medication</li>
                <li>Home exercise plan</li>
                <li>Chiropractor</li>
                <li>Osteopath</li>
                <li>Pilates</li>
                <li>
                  Medical investigations (used alongside a medical
                  practitioner’s guidance)
                </li>
                <li>GP guidance</li>
              </ul>
              {/* Removed as not present on https://www.dropbox.com/sh/k807e6jmtllxwvr/AAAwSoBgq6CwX_6KwR3zE6Z9a/3.%20Mental%20Health.docx?dl=0
              https://2-app.donedone.com/6110/project/20646/task/1108941 */}
              {/* <p>
                <a
                  className="external-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.hse.gov.uk/sicknessabsence/msd.htm"
                >
                  HSE: MSD return to work
                </a>
              </p>
              <p>
                <a
                  className="external-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.nhs.uk/conditions/back-pain/"
                >
                  NHS: Back pain overview
                </a>
              </p> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}
