import { camelCase, capitalize, kebabCase } from "lodash";

export const slug = (string) => kebabCase(string);
export const unslug = (string) => camelCase(string);
export const human = (string) => capitalize(string);
export const dir = (string) => camelCase(string);

export const winHeight = () =>
  document.getElementById("root").getBoundingClientRect().height;

// export const winScroll = () =>
//   document.getElementById("root").scrollTop -
//   document.getElementById("root").clientTop;

export const isUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (e) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};
