import React, { Component } from "react";
import SvgHandler from "../../../lib/SvgHandler";
import svg from "-!svg-inline-loader?removeSVGTagAttrs=false!../../../components/svg/graphic-checklist.svg";

import { startGreensockAnimations } from "../../../lib/greensockAnimations";

const info = {
  title: "Developing a strategy",
  description: `Having a mental health strategy in place can ensure that both employee and employer understand what it is expected, and that everyone is treated equally, effectively and with empathy.`,
};

export default class extends Component {
  static info = info;
  state = SvgHandler.initState({
    svgs: [svg],
    context: this,
    labels: [
      "Tackle discrimination",
      "Develop strategies",
      "Manager training",
      "Clear policies",
    ],
  });
  componentDidMount() {
    startGreensockAnimations();
  }
  render = () => (
    <>
      <div
        className={
          `graphic` +
          (this.state.svgActive ? ` active--` + this.state.svgActive : "")
        }
      >
        {/* Display SVG(s) */}
        {SvgHandler.svgs && (
          <>
            {SvgHandler.svgs.map((svg, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: svg }} />
            ))}
          </>
        )}
        <div className="graphic_text">
          {this.state.svgActive === "0" && (
            <>
              <h3>
                Tackle any potential for discrimination and stigma around mental
                ill-health
              </h3>
              <ul>
                <li>
                  Create an awareness of mental health and wellbeing to help
                  reduce the potential for discrimination and stigma
                </li>
                <li>
                  Have a zero-tolerance approach to discrimination and encourage
                  reporting
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "1" && (
            <>
              <h3>Develop and maintain wellbeing strategies and activities </h3>
              <ul>
                <li>Develop wellbeing activities and knowledge resources</li>
                <li>
                  Ensure everyone can participate, including part-time workers,
                  shift workers, remote workers etc.
                </li>
                <li>
                  Activities can often reduce or stop entirely when an
                  organisation or team is busy — this is the time that
                  activities need to be maintained and promoted more
                </li>
                <li>
                  Work towards mental health being given the same priority as
                  physical health
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "2" && (
            <>
              <h3>Manager training</h3>
              <ul>
                <li>
                  Ensure managers know how to handle a conversation about mental
                  health, identify signs when an employee is struggling and
                  respond to pleas for help
                </li>
                <li>
                  Make sure managers know what support/resources are available
                  to them and the employee
                </li>
                <li>
                  Embed a top-down approach. Senior leaders should prioritise
                  mental health, and champion positive behaviours and attitudes
                </li>
              </ul>
            </>
          )}
          {this.state.svgActive === "3" && (
            <>
              <h3>Have clear mental health policies</h3>
              <ul>
                <li>
                  Integrate mental health and wellbeing into policies and
                  practises relating to managing people, including employment
                  rights and working conditions
                </li>
                <li>Consider all policies through a mental wellbeing lens</li>
                <li>
                  Look at the employee journey — job design, selection,
                  recruitment, training, development and appraisal and see how
                  they promote mental wellbeing and reduce potential for
                  stigma/discrimination
                </li>
                <li>
                  Ensure communication and inclusion takes the nature of work,
                  the workforce and culture of the organisation into account
                </li>
                <li>
                  Pay particular attention to policies that relate to absence
                  management and making adjustments — e.g. flexible working is
                  likely to be beneficial.
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
