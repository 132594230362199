import React, { Component } from "react";

import icons from "../../../components/Icons";
import Oblong from "../../../components/Oblong";

const info = {
  title: "What else do you need to know?",
  description: `When an employee is off sick, there’s lots of terminology to get your head around. For example, you might hear about fit notes, certification, occupational health and early intervention information.`,
};

export default class extends Component {
  static info = info;

  render = () => {
    return (
      <>
        <h3>Fit notes and certification</h3>
        <Oblong>
          <ul className="facts" data-facts-count="2">
            <li className="fact">
              {icons.certificate}
              <blockquote>
                Employers are entitled to ask for confirmation of sickness
                absence – called self-certification
              </blockquote>
            </li>
            <li className="fact">
              {icons.calendar}
              <blockquote>
                After 7 days of absence, employers can ask for a fit note from
                the employee’s treating GP
              </blockquote>
              <cite>
                <a
                  href="https://www.nhs.uk/common-health-questions/caring-carers-and-long-term-conditions/when-do-i-need-a-fit-note/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NHS: When do I need a fit note?
                </a>
              </cite>
            </li>
          </ul>
        </Oblong>
        <div className="icon-row">
          {icons.certificate}
          <div>
            <h4>What is a fit note and how can it help?</h4>
            <ul className="columns--2">
              <li>
                A fit note is a GP’s opinion of an employee’s fitness to work
              </li>
              <li>
                Gives you more information on how their condition affects their
                work
              </li>
              <li>
                It can help you decide whether they can work in some capacity
              </li>
            </ul>
          </div>
        </div>
        <h4>What if you receive a fit note without much information?</h4>
        <ul className="columns--2">
          <li>Discuss the fit note with the employee</li>
          <li>Discuss the fit note with the employee</li>
          <li>
            Ask them to speak to their GP to get more information so you can
            help plan their return to work
          </li>
          <li>
            Remember, the GP may not be aware of their job role or the
            adjustments you can make
          </li>
          <li>
            Consider sending this information to the GP so they can provide more
            accurate guidance
          </li>
        </ul>
        <h4>Why the fit note? </h4>
        <ul className="columns--2">
          <li>
            In most cases, people do not need to be fully recovered to return to
            work
          </li>
          <li>Work can support recovery and wellbeing</li>
          <li>
            Early intervention and providing suitable work is good for people’s
            mental and physical health
          </li>
        </ul>
        {/* Removed as no longer present in copy doc */}
        {/* <cite>Getting the most of the fit note - guide for ERs – gov.uk</cite> */}
        <h3>Occupational health</h3>
        <div className="icon-row">
          {icons.health}
          <div>
            <p>
              Occupational health (OH) professionals are medically-trained
              individuals who specialise in work/health relationships.
            </p>
            <p>
              If you’re unsure if your employee is fit to work, OH will consult
              with them and provide a medical assessment typically outlining:
            </p>
            <ul className="columns--2">
              <li>The employee’s ability to work</li>
              <li>Expected return date</li>
              <li>Any recommended adjustments</li>
            </ul>
            <p>This will help you design your return-to-work plan.</p>
          </div>
        </div>
        <h3>Early intervention</h3>
        <ul className="columns--2">
          <li>Could help reduce sickness absence</li>
          <li>Makes employees feel valued and supported</li>
          <li>Can reduce employees’ worries</li>
          <li>Helps identify barriers to returning to work</li>
        </ul>
      </>
    );
  };
}
