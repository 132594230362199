import {
  // TweenLite,
  // TimelineLite,
  TimelineMax,
  TweenMax,
  Elastic,
  Linear,
  Power2,
} from "gsap/all";

// Build ignores greensock for some foolish reason: so..
// https://github.com/greensock/GreenSock-JS/issues/285#issuecomment-433729632
import CSSPlugin from "gsap/CSSPlugin";
const C = CSSPlugin;
let pulsingInterval, pulseAnswersInterval;

const considerStartingGreensockScroll = () =>
  considerStartingGreensockAnimations("scroll");

export const startGreensockAnimations = () => {
  // Start animations ONLY if considered appropriate

  // On load
  setTimeout(() => {
    considerStartingGreensockAnimations("init");
  }, 500);

  // On scroll (on small monitors, when graphic isn't visible start when scrolling down)
  // Destroy previous scroll listener
  document
    .getElementById("root")
    .removeEventListener("scroll", considerStartingGreensockScroll);
  // After a delay (as Mac OS triggers scrolls during load), add new scroll listener
  setTimeout(() => {
    document
      .getElementById("root")
      .addEventListener("scroll", considerStartingGreensockScroll);
  }, 1500); // keep this number high as poss

  // Trigger on page resize
  window.addEventListener("resize", () => {
    considerStartingGreensockAnimations("resize");
  });
};

const ifEnoughBbIsOnScreen = (bb) => {
  const { top, bottom, height } = bb;
  const enough = (height * 2) / 3; // originally height/2, for half the box
  return bottom - enough < window.innerHeight && top + enough > 0;
};

const considerStartingGreensockAnimations = (why) => {
  const graphic = document.querySelectorAll(
    ".graphic svg, .graphic-continuum svg"
  );
  graphic &&
    graphic.forEach((g) => {
      const bb = g.getBoundingClientRect();
      if (ifEnoughBbIsOnScreen(bb)) {
        const main = document.querySelector("main");
        if (main.classList.contains("ready-for-graphic-animation")) {
          main.classList.remove("ready-for-graphic-animation");
          // console.log("actuallyStartGreensockAnimations", {
          //   why,
          //   top: bb.top,
          //   bottom: bb.bottom,
          //   height: bb.height,
          //   innerHeight: window.innerHeight,
          // });
          actuallyStartGreensockAnimations();
        }
      }
    });
};

const getTransformOriginFromSvgElement = (e) =>
  e.getAttribute("transform-origin")
    ? e.getAttribute("transform-origin")
    : "50% 50%";

const animationsHotspotsTypical = (hotspots) => {
  const graphic = document.querySelector(".graphic svg");

  let animationType = "default";

  // Sometimes there are only points, such as on the person graphic, which are considered as the hotspots
  // const hasBothHotspotAndPoint =
  //   h.nextSibling && h.nextElementSibling.classList.contains("point");
  // Bit dumb, but if a hotspot or two is proceeded directly by a <g class="point">
  // const hasBothHotspotAndPoint =
  //   (hotspots[0] &&
  //     hotspots[0].nextElementSibling &&
  //     hotspots[0].nextElementSibling.classList.contains("point")) ||
  //   (hotspots[3] &&
  //     hotspots[3].nextElementSibling &&
  //     hotspots[3].nextElementSibling.classList.contains("point")); // e.g. graphic-managing-procon-4

  // Order them as God intended
  // Sort by index target
  hotspots = hotspots.sort(
    (a, b) =>
      parseInt(a.getAttribute("data-hotspot")) -
      parseInt(b.getAttribute("data-hotspot"))
  );

  if (graphic.classList.contains("animations-hotspots-and-points")) {
    animationType = "hotspotsAndPoints";
  } else if (graphic.classList.contains("animations-hotspots-person")) {
    animationType = "person";
    // Person - Sort by vertical position
    hotspots = hotspots.sort((a, b) => {
      return a.getBoundingClientRect().top - b.getBoundingClientRect().top;
    });
  }

  let fadeDelay = 0.4,
    fadeDuration = 2.5,
    fadeDurationHotspots = 1.5,
    hoverDuration = 1.8,
    initialDelayHotspotsAndPoints = 3.5,
    initialDelayHotspots = 3.2; // increased for whatIsStress

  hotspots.forEach((h, i) => {
    let transformOrigin = getTransformOriginFromSvgElement(h);

    //
    // Fade in sequentially
    //
    // console.log("animationType", animationType);
    if (animationType === "hotspotsAndPoints") {
      // - Points first
      if (h.nextElementSibling.classList.contains("point")) {
        TweenMax.fromTo(
          h.nextSibling,
          fadeDuration,
          {
            opacity: 0,
          },
          {
            delay: initialDelayHotspotsAndPoints + i * fadeDelay,
            opacity: 1,
          }
        );
      }
      // - Hotspots
      TweenMax.fromTo(
        h,
        fadeDuration,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay:
            hotspots.length * fadeDelay +
            initialDelayHotspotsAndPoints +
            i * fadeDelay,
          // delay: 3.5 + fadeDelay * hotspots.length + i * fadeDelay
        }
      );
    } else {
      TweenMax.fromTo(
        h,
        fadeDurationHotspots,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: initialDelayHotspots + i * fadeDelay,
        }
      );
    }

    //
    // Hover jiggliness
    //
    h.addEventListener("mouseenter", (e) => {
      if (e.target.tagName !== "a") return;
      e.stopPropagation();
      e.stopImmediatePropagation();
      if (!h.jiggling) {
        h.jiggling = true;
        TweenMax.to(h, hoverDuration, {
          scale: 1.2,
          transformOrigin,
          ease: Elastic.easeOut.config(1, 0.3),
          onComplete: () => {
            h.jiggling = false;
          },
        });
        // IE11 keeps spazzing mouseover events during tween, so carefully debounce around it
        setTimeout(() => {
          h.jiggling = false;
        }, 100);
      }
    });
    h.addEventListener("mouseleave", (e) => {
      if (e.target.tagName !== "a") return;
      e.stopPropagation();
      e.stopImmediatePropagation();
      clearInterval(h.mouseoutTimeout);
      h.mouseoutTimeout = setInterval(() => {
        if (!h.jiggling) {
          clearInterval(h.mouseoutTimeout);
          TweenMax.to(h, hoverDuration, {
            scale: 1,
            transformOrigin,
          });
        }
      }, 200);
    });
  });

  if (graphic.classList.contains("animations-no-jiggle")) {
    return;
  }

  let staggeredPulse;
  if (animationType === "person") {
    staggeredPulse = () => {
      TweenMax.staggerFromTo(
        hotspots, // targets
        0.4, // duration of animation, each way
        {
          scale: 1,
        },
        {
          scale: 1.4,
          repeat: 1,
          yoyo: true,
          transformOrigin: (a, h, c) => getTransformOriginFromSvgElement(h),
        },
        0.15 // time between each item's animation
      );
    };
  } else {
    staggeredPulse = () => {
      TweenMax.staggerFromTo(
        hotspots, // targets
        0.4, // duration of animation, each way
        {
          scale: 1,
        },
        {
          scale: 1.1,
          repeat: 1,
          yoyo: true,
          transformOrigin: (a, h, c) => getTransformOriginFromSvgElement(h),
        },
        0.2 // time between each item's animation
      );
    };
  }
  setTimeout(() => {
    clearInterval(pulsingInterval);
    pulsingInterval = setInterval(() => {
      staggeredPulse();
    }, 3500);
  }, 6000);
};

const animationshotspotsFeet = (foots) => {
  let fadeDuration = 0.3,
    fadeDelay = 0.5,
    initialDelay = 3;
  foots.forEach((f, i) => {
    TweenMax.fromTo(
      f,
      fadeDuration,
      {
        opacity: 0,
        scale: 1.2,
        x: -30,
        y: -15,
      },
      {
        opacity: 1,
        scale: 1,
        x: 0,
        y: 0,
        delay: initialDelay + i * fadeDelay,
      }
    );
  });

  setTimeout(() => {
    clearInterval(pulsingInterval);
    pulsingInterval = setInterval(() => {
      TweenMax.staggerTo(
        foots, // targets
        0.3, // duration of animation, each way
        {
          scaleY: 1.1,
          x: -15,
          y: -7,
          // skewY: -10,
          // rotation: 10,
          repeat: 1,
          yoyo: true,
        },
        0.2 // time between each item's animation
      );
    }, 3500);
  }, 6000);
};

const animationsLeafs = (leafs, stalks) => {
  const growDuration = 1,
    initialDelay = 4; // 2
  stalks.reverse().forEach((s, i) => {
    TweenMax.fromTo(
      s,
      growDuration,
      {
        scale: 0, // scaleY
        transformOrigin: "50% 100%",
      },
      {
        scale: 1,
        delay: initialDelay + i * growDuration,
      }
    );
  });
  leafs.forEach((l, i) => {
    TweenMax.fromTo(
      l,
      growDuration,
      {
        scale: 0,
        transformOrigin: ["100% 50%", "100% 50%", "0% 50%", "0% 50%"][i],
      },
      {
        scale: 1,
        delay: initialDelay + stalks.length * growDuration + i * growDuration,
      }
    );
  });
};

const animationsContinuum = (svg) => {
  const pulseAnswers = (selector) => {
    const answers = document.querySelectorAll(selector); // targets
    TweenMax.staggerFromTo(
      answers,
      0.4, // duration of animation, each way
      {
        scale: 1,
      },
      {
        scale: 1.15,
        repeat: 1,
        yoyo: true,
        transformOrigin: (a, h, c) => getTransformOriginFromSvgElement(h),
      },
      0.2 // time between each item's animation
    );
  };

  const fadeElementsIn = (selector, initialDelay, shouldScaleAsWell) => {
    const answers = document.querySelectorAll(selector); // targets
    let fadeDelay = 0.5,
      fadeDuration = 1.5;

    answers.forEach((element, i) => {
      TweenMax.fromTo(
        element,
        fadeDuration,
        {
          opacity: 0,
          scale: shouldScaleAsWell ? 0 : 1,
          transformOrigin: getTransformOriginFromSvgElement(element),
        },
        {
          opacity: 1,
          scale: 1,
          delay: initialDelay + i * fadeDelay,
        }
      );
    });
  };
  fadeElementsIn(".continuum.-init .line_segment", 2.5, true);
  fadeElementsIn(".continuum.-init .axis_label", 4.5);
  fadeElementsIn(".continuum.-init .answer", 6.5);
  fadeElementsIn(".continuum.-init .quadrant_label", 6.5);
  fadeElementsIn(".continuum.-init aside", 8.5);

  clearInterval(pulseAnswersInterval);
  pulseAnswersInterval = setInterval(() => {
    pulseAnswers(".continuum.-questions .answer");
  }, 3500);
};

const actuallyStartGreensockAnimations = () => {
  // Central icon fade and scale in
  let icon = document.querySelector("g.icon");
  if (icon) {
    TweenMax.fromTo(
      icon,
      2,
      {
        scale: 0.8,
        opacity: 0,
        transformOrigin: "50% 50%",
      },
      {
        scale: 1,
        opacity: 1,
        ease: Elastic.easeOut.config(1, 0.5),
        delay: 1.5,
      }
    );
  }

  // Similarly if a person graphic
  let person = document.querySelector("g.person");
  if (person) {
    TweenMax.fromTo(
      person,
      2,
      {
        scale: 0.8,
        opacity: 0,
        transformOrigin: "50% 50%",
      },
      {
        scale: 1,
        opacity: 1,
        ease: Elastic.easeOut.config(1, 0.5),
        delay: 1.5,
      }
    );
  }

  // Set the ring a-spinnin'
  let ring = document.querySelector("circle.ring");
  if (ring) {
    TweenMax.to(ring, 3, {
      rotation: -12, // arbitrary guesswork
      ease: Power2.easeIn,
      transformOrigin: "50% 50%",
      opacity: 1,
      delay: 1,
    });

    TweenMax.to(ring, 50, {
      rotation: -360,
      ease: Linear.easeNone,
      transformOrigin: "50% 50%",
      repeat: -1,
      delay: 3 + 1,
    });
  }

  // Grab hotspots
  let hotspots = [
    ...document.querySelectorAll(".animations-hotspots-typical .hotspot"),
  ];
  if (hotspots.length) {
    animationsHotspotsTypical(hotspots);
  }

  let hotspotsFeet = [
    ...document.querySelectorAll(".animations-hotspots-feet .foot"),
  ];
  if (hotspotsFeet.length) {
    animationshotspotsFeet(hotspotsFeet);
  }

  let leafs = [...document.querySelectorAll("path.leaf")];
  let stalks = [...document.querySelectorAll("path.stalk")];
  if (leafs.length && stalks.length) {
    animationsLeafs(leafs, stalks);
  }

  // release all other pending CSS animations, with a 2.5s delay
  const graphic = document.querySelector(".graphic svg");
  if (graphic) {
    const delay = graphic.classList.contains("animations-hotspots-and-points")
      ? 3500
      : 2500;
    setTimeout(() => {
      graphic.classList.remove("hold-animation");
    }, delay);
  }

  // Set the starburst a-spinnin'
  let starburst = document.querySelector(".starburst");
  if (starburst) {
    TweenMax.to(starburst, 3, {
      rotation: -12, // arbitrary guesswork
      ease: Power2.easeIn,
      transformOrigin: "50% 50%",
      opacity: 1,
      scale: 1,
      delay: 1,
    });

    TweenMax.to(starburst, 7, {
      rotation: -360,
      ease: Linear.easeNone,
      transformOrigin: "50% 50%",
      repeat: -1,
      delay: 3 + 1,
    });
  }

  // Arrow circular rotation (graphics-hands)
  let arrow = document.querySelector(".animations-circular-arrow g.arrow path");
  if (arrow) {
    //   // Whip out CSS / inline properties to figure out exactly how to rotate and offset the dashes. The SVGs are inconsistent, so it's a whole thing
    const circumference = parseInt(arrow.getAttribute("data-circumference"));
    const initialRotation = parseInt(
      arrow.getAttribute("data-initial-rotation")
    );
    const shorteningFactor = parseFloat(
      arrow.getAttribute("data-shortening-factor")
    );
    // arrow.setAttribute("stroke-dasharray", circumference);

    // console.log(
    //   circumference,
    //   shorteningFactor,
    //   circumference * shorteningFactor,
    //   initialRotation,
    //   (1 - shorteningFactor) / 2
    // );

    // Don't use CSS for initial hide, as it breaks IE11
    arrow.style.opacity = 0;

    setTimeout(() => {
      TweenMax.fromTo(
        arrow,
        6,
        {
          // Originally anti-clockwise
          // strokeDasharray: circumference * shorteningFactor,
          rotation: initialRotation,
          strokeDashoffset: circumference,
          transformOrigin: "50% 50%",
          strokeDasharray: circumference,
        },
        {
          //
          // Originally anti-clockwise (if necessary revert various elements and params in all the SVGs from before this commit)
          // rotation: -360 - initialRotation,
          // strokeDashoffset: 0,
          rotation: initialRotation + 360 - (1 - shorteningFactor) * 360,
          strokeDashoffset: (1 - shorteningFactor) * circumference,
          //
          opacity: 1,
          ease: Power2.easeOut,
          delay: 0, // use timeout instead, so as to grab non-inline CSS styles beforehand
        }
      );
    }, 2500);
  }

  const svgContinuum = document.querySelector("continuum-svg svg");
  if (svgContinuum) {
    animationsContinuum(svgContinuum);
  }
};
